import { action, computed, flow, makeObservable, observable, reaction, when } from 'mobx';

import { mapItems } from 'src/api/new-well/serializers/common-serializers';
import { TItemRaw } from 'src/api/new-well/types';
import { OperationAbortedError } from 'src/errors';
import { SwitchButtonGroupItem } from 'src/shared/components/switch-button-group/switch-button-group';
import { hasValue } from 'src/shared/utils/common';
import { debounce } from 'src/shared/utils/debounce';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { FilterForm } from 'src/store/filter-form/filter-form';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { ChartGroupType, isChartGroupType } from './chart-group-type';
import { ChartGrouping } from './chart-grouping';

export class FiltersFormStore {
  private readonly planVersionId: number;
  private readonly view: ChartFiltersForm.View;
  private readonly directories: Directories;
  private readonly rootStore: RootStore;
  private readonly notifications: NotificationsStore;
  private readonly _onSubmit: ChartFiltersForm.SubmitHandler;
  private readonly _onChange: ChartFiltersForm.ChangeHandler;

  private changeCancellationController = new AbortController();

  @observable private groupingData?: ChartFiltersForm.ViewGrouping;
  @observable private grouping?: ChartGrouping;

  @observable isOpen = false;
  // Changed when submitting.
  @observable isLoading = false;
  // Changed when filters changing.
  @observable isBackgroundLoading = false;
  @observable filteringDataLength: number | null = null;
  @observable form?: FilterForm;
  /** Applied (saved on 'apply' button click) form values. */
  @observable formValues?: ChartFiltersForm.FormValues;

  constructor(
    planVersionId: number,
    view: ChartFiltersForm.View,
    rootStore: RootStore,
    onSubmit: ChartFiltersForm.SubmitHandler,
    onChange: ChartFiltersForm.ChangeHandler,
    chartGrouping?: ChartGrouping
  ) {
    this.planVersionId = planVersionId;
    this.view = view;
    this.directories = rootStore.directories;
    this.notifications = rootStore.notifications;
    this.rootStore = rootStore;
    this._onSubmit = onSubmit;
    this._onChange = onChange;
    this.grouping = chartGrouping;

    makeObservable(this);
  }

  @computed
  private get actualFormValues(): ChartFiltersForm.FormValues {
    const filter: ChartFiltersForm.FilterValues = {};
    const grouping = this.groupingValue;
    const fields = this.form?.fieldsArray;

    if (fields?.length) {
      for (const field of fields) {
        const value = field.value;

        if (hasValue(value) && hasValue(field.formElementRefId)) {
          filter[field.formElementRefId] = value;
        }
      }
    }

    return { grouping, filter };
  }

  @flow.bound
  private async *updateFilteringDataLength(
    filter: ChartFiltersForm.FilterValues,
    grouping: ChartGroupType | null
  ): Promise<void> {
    try {
      this.isBackgroundLoading = true;

      this.changeCancellationController.abort();
      this.changeCancellationController = new AbortController();

      const dataLength = await this._onChange(filter, grouping, this.changeCancellationController.signal);
      yield;

      this.filteringDataLength = dataLength;
      this.isBackgroundLoading = false;
    } catch (e) {
      yield;

      if (!(e instanceof OperationAbortedError)) {
        console.error(e);
      }
    }
  }

  private updateFilteringDataLengthDebounced = debounce(
    (filter: ChartFiltersForm.FilterValues, grouping: ChartGroupType | null) =>
      this.updateFilteringDataLength(filter, grouping),
    500
  );

  @action.bound
  private saveFormValues(): void {
    const { filter, grouping } = this.actualFormValues;
    const emptyValues: ChartFiltersForm.FilterValues = {};

    if (this.form) {
      // Add fields missing in the filters as empty value to keep them too.
      for (const field of this.form.fieldsArray) {
        if (field.formElementRefId && !Object.hasOwn(filter, field.formElementRefId)) {
          emptyValues[field.formElementRefId] = undefined;
        }
      }
    }

    this.formValues = {
      grouping,
      filter: { ...emptyValues, ...filter },
    };
  }

  @action.bound
  private applySavedValues(): void {
    if (this.formValues && this.form) {
      this.form.setValues(this.formValues.filter);
      this.grouping?.setValue(this.formValues.grouping ?? null);
    }
  }

  @flow.bound
  async *loadData(): Promise<void> {
    try {
      const { filter: filterControls, grouping } = this.view;

      const objectRefs = filterControls.reduce((refs: string[], item) => {
        if ('refObjectType' in item && item.refObjectType) {
          refs.push(item.refObjectType);
        }

        return refs;
      }, []);

      await this.directories.loadObjects(objectRefs);
      yield;

      const initializedControls = mapItems(filterControls, this.directories);

      this.form = new FilterForm(initializedControls, this.rootStore, {
        planVersionId: this.planVersionId,
      });

      this.groupingData = grouping;
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToLoadFilters');
    }
  }

  @computed
  get groupingValue(): ChartGroupType | null {
    if (!this.groupingData || !this.grouping) {
      return null;
    }

    return this.grouping.value;
  }

  @computed
  get groupingLabel(): string | null {
    if (!this.groupingData) {
      return null;
    }

    return this.directories.getFieldLabel(this.groupingData.fieldId);
  }

  @computed
  get groupingOptions(): SwitchButtonGroupItem<string>[] {
    if (!this.groupingData) {
      return [];
    }
    return this.groupingData.values.map(({ fieldId, value }) => ({
      key: value,
      title: this.directories.getFieldLabel(fieldId) || '',
    }));
  }

  @action.bound
  onGroupingChange(value: string): void {
    if (!isChartGroupType(value)) {
      this.notifications.showErrorMessageT('errors:invalidGroupingSelected');
      return;
    }

    this.grouping?.setValue(value);
  }

  @action.bound
  init(): VoidFunction {
    const disposeFiltersUpdating = reaction(
      () => this.actualFormValues,
      (formValues) => {
        if (formValues) {
          this.updateFilteringDataLengthDebounced(formValues.filter, formValues.grouping || null);
        }
      }
    );

    const disposeValuesSaving = when(
      () => this.isOpen,
      () => {
        this.saveFormValues();
      }
    );

    return () => {
      disposeFiltersUpdating();
      disposeValuesSaving();
    };
  }

  @action.bound
  onFiltersReset(): void {
    this.grouping?.setValue(null);
    this.form?.fieldsArray.map((field) => field.clearItem());
  }

  @flow.bound
  async *onSubmit(): Promise<void> {
    try {
      this.isLoading = true;

      this.saveFormValues();

      const filter = this.formValues?.filter;

      await this._onSubmit(filter || {}, this.formValues?.grouping || null);

      this.onClose();
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToApplyFilters');
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  onOpen(): void {
    this.isOpen = true;
  }

  @action.bound
  onClose(): void {
    this.isOpen = false;
  }

  @action.bound
  onCancel(): void {
    this.applySavedValues();
    this.onClose();
  }
}

export namespace ChartFiltersForm {
  export type ViewGroupingValue = {
    value: string;
    fieldId: string;
  };

  export type ViewGrouping = {
    attrName: string;
    control: string;
    fieldId: string;
    values: ViewGroupingValue[];
  };

  export type View = {
    filter: TItemRaw[];
    grouping: ViewGrouping;
  };

  export type FilterValues = Record<string, unknown>;

  export type SubmitHandler = (values: FilterValues, grouping: ChartGroupType | null) => Promise<void>;

  export type ChangeHandler = (
    values: FilterValues,
    grouping: ChartGroupType | null,
    cancellationSignal?: AbortSignal
  ) => Promise<number>;

  export type FormValues = { filter: ChartFiltersForm.FilterValues; grouping: ChartGroupType | null };
}
