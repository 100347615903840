import { action, flow, makeObservable, observable } from 'mobx';

import { DraftApi } from 'src/api/draft';
import {
  cleanTripleValidation,
  deletePlanVersion,
  deleteTriple,
  fetchTriple,
  fetchTriplesList,
  TripleType,
} from 'src/api/import-file';
import { getWellByWellId } from 'src/api/new-well/requests';
import { TCreateUpdateWellReturnType } from 'src/api/new-well/types';
import { BaseApiError } from 'src/errors';
import { RootStore } from 'src/store';
import { ComparisonStore } from 'src/store/comparison/comparison-store';
import { PlanVersion, PlanVersionType } from 'src/store/comparison/types';
import { DraftsStore } from 'src/store/drafts/drafts-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { PlanVersionStore } from 'src/store/plan-version';
import { RouterStore as Router } from 'src/store/router/router-store';
import { WellFormManager } from 'src/store/well-form-manager/well-form-manager';

export class ImportConflictsStore {
  private readonly notifications: NotificationsStore;
  private readonly router: Router;
  private readonly planVersionId: number;
  private readonly planVersion: PlanVersionStore;
  private readonly comparison: ComparisonStore;
  private readonly draftsStore: DraftsStore;
  private readonly api = new DraftApi();
  readonly formManager: WellFormManager;

  @observable triple?: TripleType;
  @observable validationErrors?: Record<string, string>;
  @observable rawValues?: Record<string, unknown>;
  @observable isLoading: boolean = false;

  constructor(rootStore: RootStore, planVersionId: number) {
    this.notifications = rootStore.notifications;
    this.router = rootStore.router;
    this.planVersionId = planVersionId;
    this.planVersion = rootStore.planVersion;
    this.draftsStore = rootStore.drafts;
    this.comparison = rootStore.comparison;
    this.formManager = new WellFormManager(rootStore);

    makeObservable(this);
  }

  @action.bound
  effect() {
    this.formManager.onFormInit = this.initForm;
    this.formManager.onFormCancel = this.onFormCancel;
    this.formManager.onUpdateWell = this.onWellUpdate;
    this.formManager.onFormSaveSuccess = this.onFormSave;

    this.formManager.init();
  }

  @action.bound
  setValidationErrors(errors: Record<string, string>): void {
    this.validationErrors = errors;
  }

  @action.bound
  setRawValues(errors: Record<string, unknown>): void {
    this.rawValues = errors;
  }

  @action.bound
  resetTriple() {
    this.triple = undefined;
    this.validationErrors = undefined;
  }

  @action.bound
  setTriple(triple: TripleType) {
    this.triple = triple;
  }

  @flow.bound
  private async *compareVersions() {
    try {
      const planVersions: PlanVersion[] = await this.api.getVersions();
      yield;

      const importPlanVersion = planVersions.find((version) => version.id === this.planVersionId);
      const currentVersion = this.planVersion.actualVersions.find(
        (version) => version.data.versionType === PlanVersionType.current
      );

      if (!this.planVersion.version || !importPlanVersion || !currentVersion) {
        return;
      }

      this.comparison.submitComparingWithImport(currentVersion, importPlanVersion);
      this.formManager.resetFormManager();
      this.formManager.setIsFormOpen(false);

      await this.draftsStore.fetchDraftsList();
      yield;

      this.router.push('/');

      return;
    } catch (error) {
      yield;

      console.error(error);
      if (error instanceof BaseApiError && error.responseMessage) {
        this.notifications.showErrorMessage(error.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToLoadVersions');
    }
  }

  @flow.bound
  private async *initForm() {
    try {
      const tripleList = await fetchTriplesList(this.planVersionId);
      yield;

      if (!this.draftsStore.draftVersionId) {
        const newImportDraft = this.draftsStore.draftsList.find((draft) => draft.id === this.planVersionId);

        if (newImportDraft) {
          this.draftsStore.setDraft(newImportDraft.id, newImportDraft.data.parentVersionId);
        } else {
          throw new Error('import draft is not presented');
        }
      }

      const filteredTriplesIdList = tripleList?.triples?.filter((triple) => triple.hasErrors);

      try {
        const tripleId = filteredTriplesIdList[0]?.id;
        if (!tripleId) {
          this.compareVersions();

          return;
        }

        const triple = await fetchTriple(tripleId);
        yield;

        this.setTriple(triple);

        if (!triple.$validation) {
          return;
        }

        const normalizedErrors = triple.$validation?.reduce<Record<string, string>>((acc, next) => {
          acc[next.fieldId] = next.message;

          return acc;
        }, {});

        this.setValidationErrors(normalizedErrors);

        const normalizedRawValues = triple.$validation?.reduce<Record<string, unknown>>((acc, next) => {
          acc[next.fieldId] = next.rawValue;

          return acc;
        }, {});

        this.setRawValues(normalizedRawValues);
      } catch (error) {
        yield;
        console.error(error);
        this.notifications.showErrorMessageT('errors:failedToLoadWellData');

        return;
      }
    } catch (error) {
      yield;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadWells');

      return;
    }

    try {
      const geologicalTaskId = this.triple?.geologicalTaskId;

      if (!geologicalTaskId || !this.planVersionId) return;

      const well = await getWellByWellId(geologicalTaskId, this.planVersionId);
      yield;

      this.formManager.createForm({ well, setBy: 'attrName' });

      if (this.rawValues && this.formManager.currentFormStore) {
        const excludeFalsyValues = true;
        this.formManager.currentFormStore.setFormValues(this.rawValues, excludeFalsyValues);
      }
      if (this.validationErrors && this.formManager.currentFormStore) {
        this.formManager.currentFormStore.setServerErrors(this.validationErrors);
      }

      this.formManager.setIsFormOpen(true);
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadWell');
    }
  }

  @flow.bound
  async *fetchTripleData(tripleId: number) {
    try {
      const triple = await fetchTriple(tripleId);
      yield;

      this.setTriple(triple);

      if (!triple.$validation) {
        return;
      }

      const normalizedErrors = triple.$validation?.reduce<Record<string, string>>((acc, next) => {
        acc[next.fieldId] = next.message;

        return acc;
      }, {});

      this.setValidationErrors(normalizedErrors);

      const normalizedRawValues = triple.$validation?.reduce<Record<string, unknown>>((acc, next) => {
        acc[next.fieldId] = next.rawValue;

        return acc;
      }, {});

      this.setRawValues(normalizedRawValues);
      this.syncDataWithForm();
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadWellData');
    }
  }

  @flow.bound
  async *fetchNextTriple() {
    if (this.isLoading) return;

    this.resetTriple();

    try {
      this.isLoading = true;

      const tripleList = await fetchTriplesList(this.planVersionId);
      yield;

      const filteredTriplesIdList = tripleList?.triples?.filter((triple) => triple.hasErrors);
      const nextTripleId = filteredTriplesIdList[0]?.id;

      if (nextTripleId) {
        this.fetchTripleData(nextTripleId);
      } else {
        this.compareVersions();
      }
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadWells');

      return;
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  private async *onFormCancel() {
    if (!this.planVersionId) return;

    try {
      await deletePlanVersion(this.planVersionId);
      await this.draftsStore.fetchDraftsList();
      yield;

      this.formManager.setIsFormOpen(false);
      this.formManager.resetFormManager();
      this.router.push('/');
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToDeletePlanVersion');
    }
  }

  @flow.bound
  async *excludeTriple() {
    if (!this.triple || !this.planVersionId) return;

    try {
      await deleteTriple(this.planVersionId, this.triple.geologicalTaskId);
      yield;

      this.fetchNextTriple();
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToExcludeWellFromImport');
    }
  }

  @action.bound
  onWellUpdate(well: TCreateUpdateWellReturnType) {
    const planWellTripleId = well.idList.find((idObject) => idObject.objectType === 'GOplan_PlanWellTriple');
    if (this.triple && planWellTripleId) {
      this.triple.id = planWellTripleId.id;
    }
  }

  @flow.bound
  async *onFormSave() {
    if (!this.planVersionId || !this.triple) return;

    try {
      await cleanTripleValidation(this.triple.id);
      yield;

      this.fetchNextTriple();
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('matching:saveError');
    }
  }

  @flow.bound
  async *syncDataWithForm() {
    const geologicalTaskId = this.triple?.geologicalTaskId;

    if (!geologicalTaskId || !this.planVersionId) return;

    const well = await getWellByWellId(geologicalTaskId, this.planVersionId);
    yield;

    this.formManager.createForm({ well, setBy: 'attrName' });

    if (this.rawValues && this.formManager.currentFormStore) {
      const excludeFalsyValues = true;
      this.formManager.currentFormStore.setFormValues(this.rawValues, excludeFalsyValues);
    }
    if (this.validationErrors && this.formManager.currentFormStore) {
      this.formManager.currentFormStore.setServerErrors(this.validationErrors);
    }
  }
}
