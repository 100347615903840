import { flow, makeObservable, observable } from 'mobx';

import { TItemRaw } from 'src/api/new-well/types';
import { getConcatedName } from 'src/shared/utils/get-concated-name';
import { isDynamicJoin } from 'src/shared/utils/is-dynamic-join';
import { RootStore } from 'src/store';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { TRefQuery } from 'src/store/directories/types';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { View } from 'src/store/views/views-store';

import { GraphInfoSidebarViewProvider } from './graph-info-sidebar-view-provider';

type TGraphInfoSidebarOptions = {
  rootStore: RootStore;
};

export abstract class GraphInfoSidebarStore {
  protected abstract readonly viewManager: View<TSidebarInfoView>;
  protected readonly directories: Directories;
  protected readonly notifications: NotificationsStore;
  protected readonly appSettings: AppSettingsStore;

  @observable isLoading = false;
  @observable viewProvider?: GraphInfoSidebarViewProvider;

  constructor({ rootStore }: TGraphInfoSidebarOptions) {
    this.directories = rootStore.directories;
    this.notifications = rootStore.notifications;
    this.appSettings = rootStore.appSettings;

    makeObservable(this);
  }

  @flow.bound
  protected async *loadView(): Promise<void> {
    this.isLoading = true;

    try {
      const view = await this.viewManager.loadView();
      yield;

      const attrNames = new Set<string>();
      const refObjects = new Set<string>();
      const joinedObjects = new Set<TRefQuery>();

      for (const tab of view.tabs) {
        if (tab.kind === 'tabWithColumns') {
          tab.columns.forEach((column) => {
            column.items.forEach((item) => {
              if ('refObjectType' in item && item.refObjectType) {
                refObjects.add(item.refObjectType);
              }
              if ('refQuery' in item && item.refQuery && !isDynamicJoin(item.refQuery)) {
                joinedObjects.add(item.refQuery);
              }
              if ('attrConcatRefs' in item) {
                for (const key in item.attrConcatRefs) {
                  if (item.attrConcatRefs[key].refObjectType) {
                    refObjects.add(item.attrConcatRefs[key].refObjectType);
                  }
                }
              }
            });
          });
        }

        if (tab.kind === 'tabWithPads') {
          const { attrName, refQuery, wells } = tab.pads;

          if (refQuery && !isDynamicJoin(refQuery)) {
            joinedObjects.add(refQuery);
          } else {
            attrNames.add(attrName);
          }

          if (wells.refObjectType) {
            refObjects.add(wells.refObjectType);
          } else {
            attrNames.add(wells.attrName);
          }
        }

        if (tab.kind === 'tabWithWells') {
          const { attrName, refObjectType } = tab.wells;

          if (refObjectType) {
            refObjects.add(refObjectType);
          } else {
            attrNames.add(attrName);
          }
        }

        if (tab.kind === 'tabWithStages') {
          if (tab.rigOperations.stages.refObjectType) {
            refObjects.add(tab.rigOperations.stages.refObjectType);

            if (tab.rigOperations.stages.sections?.refObjectType) {
              refObjects.add(tab.rigOperations.stages.sections.refObjectType);
            }
          }
        }
      }

      await Promise.all([
        this.directories.loadAttrNames([...attrNames]),
        this.directories.loadObjects([...refObjects]),
        this.directories.loadJoinedObjectsDeprecated([...joinedObjects]),
      ]);
      yield;

      this.viewProvider = new GraphInfoSidebarViewProvider(
        view,
        this.directories,
        this.appSettings,
        this.notifications
      );
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('drawers:addToChart.failedToLoadSidebar');
    } finally {
      this.isLoading = false;
    }
  }

  getSidebarTitle(item: Record<string, unknown>): string | null {
    const titleView = this.viewManager.view.title;

    if ('attrConcat' in titleView) {
      return getConcatedName(this.directories, titleView, item);
    }

    if (titleView.refObjectType && titleView.refObjectAttr && titleView.attrName) {
      const value = Number(item[titleView.attrName]);

      const directory = this.directories.getObject(titleView.refObjectType);

      if (!directory || Number.isNaN(value)) {
        return '';
      }

      const directoryValue = directory.find((dir) => dir.id === value);

      if (!directoryValue) {
        return '';
      }

      return directoryValue.data[titleView.refObjectAttr]?.toString() || '';
    }

    return null;
  }
}

export type TSidebarInfoView = {
  tabs: TTab[];
  title: TInfoSidebarTitle;
};

export type TInfoSidebarTitle = TConcatTitle | TRegularTitle;

export type TConcatTitle = {
  attrConcat: string[];
  attrConcatRefs?: Record<
    string,
    {
      refObjectAttr: string;
      refObjectType: string;
    }
  >;
  delimiter?: string;
};

export type TRegularTitle = {
  attrName: string;
  refObjectAttr: string;
  refObjectType: string;
};

export type TTab = TTabWithColumns | TTabWithPads | TTabWithWells | TTabWithStages;

export type TCommonTab = {
  fieldId: string;
};

export type TTabWithColumns = TCommonTab & {
  kind: 'tabWithColumns';
  columns: { items: TItemRaw[] }[];
};

export type TTabWithPads = TCommonTab & { kind: 'tabWithPads'; pads: PadsItem };

export type TTabWithWells = TCommonTab & { kind: 'tabWithWells'; wells: WellsItem };

export type TTabWithStages = TCommonTab & {
  kind: 'tabWithStages';
  rigOperations: TRigOperation;
};

type Attribute = {
  objectName: string;
  type: string;
};

export type WellsItem = {
  attrName: string;
  refObjectAttr?: string;
  refObjectType?: string;
  attributes?: Attribute[];
};

export type PadsItem = {
  wells: WellsItem;
  attrName: string;
  refQuery: TRefQuery;
  refObjectAttrConcat: string[];
  delimiter?: string;
};

type ViewItem = {
  attrName: string;
  objectName: string;
  refObjectAttr?: string;
  refObjectType?: string;
};

type TStageSectionAttribute = {
  type: 'DateTime' | 'Number';
  objectName: string;
};

export type TRigOperation = {
  rigOperationIndex: string;
  attrName: string;
  stages: TRigOperationStage;
};

export type TRigOperationStage = ViewItem & {
  attributes: TStageSectionAttribute[];
  sections: TRigOperationSection;
};

export type TRigOperationSection = ViewItem & {
  attributes: TStageSectionAttribute[];
};
