import { observer } from 'mobx-react-lite';
import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import i18 from 'src/i18n';
import { Router } from 'src/router/router';

import './styles/index.scss';
import { Loader } from './shared/components/loader';
import { RootStore, RootStoreProvider } from './store';

import styles from './app.module.scss';

export const App: FC = observer(function App() {
  const navigate = useNavigate();
  const [store] = useState(() => new RootStore({ navigate, i18 }));

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', store.theme.theme);
  }, [store.theme.theme]);

  useEffect(() => {
    store.init();
  }, [store]);

  if (!store.isInitialized || store.isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <RootStoreProvider store={store}>
      <Router />
    </RootStoreProvider>
  );
});
