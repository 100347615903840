import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { UnplannedRigOperationsSidebar } from 'src/api/unplanned-rig-operations-sidebar';
import { ConfirmationRemoveSidebarStore } from 'src/features/drilling-chart/presets/confirmation-remove-sidebar/confirmation-remove-sidebar.store';
import { RigOperation } from 'src/features/drilling-chart/presets/confirmation-remove-sidebar/entities/rig-operation';
import { DELETING_OBJECT } from 'src/features/drilling-chart/presets/confirmation-remove-sidebar/types';
import { SidebarPadRigOperation } from 'src/features/drilling-chart/presets/pads-and-wells-sidebar/entities/pad-rig-operation.entity';
import { SidebarWellRigOperation } from 'src/features/drilling-chart/presets/pads-and-wells-sidebar/entities/well-rig-operation.entity';
import { PadsAndWellsSidebarStore } from 'src/features/drilling-chart/presets/pads-and-wells-sidebar/pads-and-wells-sidebar.store';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { showErrorMessage } from 'src/shared/utils/messages';
import { Modal } from 'src/shared/utils/modal';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { RootStore } from 'src/store';
import { EditingStore } from 'src/store/editing/editing-store';
import { I18NextStore } from 'src/store/i18next/i18next-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { AutoScrollController } from '../../features/auto-scroll/auto-scroll-controller';
import { AddingRigCancellationError } from '../../features/data-items-full/presenter/errors';
import { InsertionPointsCalculator } from '../../features/data-items-full/presenter/insertion-points-calculator';
import { PlanRigOperationSidebarStore } from '../../features/data-items-full/presenter/plan-rig-operation-sidebar.store';
import { ShadowPadRigOperation } from '../../features/data-items-full/presenter/shadow-pad-rig-operation';
import { ShadowWellRigOperation } from '../../features/data-items-full/presenter/shadow-well-rig-operation';
import { DndContextStore } from '../../features/editing/shared/entities/dnd-context.store';
import { DraggableActive } from '../../features/editing/shared/entities/draggable-active';
import { DraggableGhost } from '../../features/editing/shared/entities/draggable-ghost';
import { DraggableGhostShadow } from '../../features/editing/shared/entities/draggable-ghost-shadow';
import { DraggingInteractive } from '../../features/editing/shared/entities/dragging-interactive';
import { DroppableActive } from '../../features/editing/shared/entities/droppable-active';
import { InsertionPointNeighbor } from '../../features/editing/shared/entities/insertion-point-neighbor';
import { Dnd } from '../../features/editing/types';
import { RigsChartDataModel } from '../../features/rigs-chart/data/rigs-chart-data-model';
import { DataView } from '../../shared/data-view/data-view';
import { DateHelper } from '../../shared/date-helper';
import { RigsDataPositionsCalculator } from '../../shared/rigs-data-positions-calculator';
import { TimelineController } from '../../shared/timeline-controller';
import { Viewport } from '../../shared/viewport/viewport';
import { moveViewport, pixelsToMilliseconds } from '../../shared/viewport/viewport-calculator';
import { IChartDndModule, IModuleDataSource } from '../../types';
import { EmptyRig } from '../add-rig-sidebar/entities/empty-rig';
import { ConflictSidebarStore } from '../conflict-sidebar/conflict-sidebar.store';
import { ConflictRigOperation } from '../conflict-sidebar/entities/conflict-rig-operation';
import {
  InsertionPoint,
  InteractiveInsertionPoint,
  PadRigOperation,
  RigsGroup,
  TemporaryChartRig,
  TemporaryPadRigOperation,
  TemporaryWellRigOperation,
  WellRigOperation,
} from '../drilling-rigs-chart/entities';
import { RigsChartStore } from '../drilling-rigs-chart/rigs-chart.store';

import { AddingRigScenario } from './adding-rig-scenario';

export class RigOperationsDndModule
  implements IChartDndModule<RigsChartStore.ViewItem[] | null, RigOperationsDnd.DndContext>
{
  private readonly dataSource: IModuleDataSource<RigsChartStore.ViewItem[] | null>;
  private readonly horizontalViewport: Viewport;
  private readonly verticalViewport: Viewport;
  private readonly viewportController: TimelineController;
  private readonly autoScrollController: AutoScrollController;
  private readonly addingRigScenario: AddingRigScenario;
  private readonly i18: I18NextStore;
  private readonly notifications: NotificationsStore;
  private readonly editing: EditingStore;
  private readonly dataController: RigsChartDataModel;
  private readonly reloadData: RigOperationsDnd.ReloadDataFn;

  readonly conflictSidebar: ConflictSidebarStore;
  readonly resolveConflict: (isResolved: boolean) => void;

  @observable private isMoving = false;
  @observable.ref private editingDataItems: RigsChartStore.ViewItem[] = [];
  @observable.ref private temporaryRig: TemporaryChartRig | null = null;
  @observable.ref private activeGhost: DraggableGhost<
    RigOperationsDnd.DraggableItem,
    RigOperationsDnd.DraggableShadow,
    RigOperationsDnd.ViewItem
  > | null = null;
  @observable.ref private itemsInTemporaryRig: RigOperationsDnd.ViewItem[] | null = null;
  @observable private isDisabled = false;

  readonly padsAndWellsSidebarStore: PadsAndWellsSidebarStore;
  readonly padsAndWellsSidebarVisibilityController = new Modal();

  readonly planRigOperationSidebar: PlanRigOperationSidebarStore;
  readonly planRigOperationSidebarVisibilityController = new Modal();

  readonly confirmationRemoveSidebar: ConfirmationRemoveSidebarStore;
  readonly dndContext: RigOperationsDnd.DndContext;

  constructor(
    dataSource: IModuleDataSource<RigsChartStore.ViewItem[] | null>,
    horizontalViewport: Viewport,
    verticalViewport: Viewport,
    viewportController: TimelineController,
    autoScrollController: AutoScrollController,
    rootStore: RootStore,
    resolveConflict: (isResolved: boolean) => void,
    dataController: RigsChartDataModel,
    reloadData: RigOperationsDnd.ReloadDataFn
  ) {
    this.i18 = rootStore.i18;
    this.editing = rootStore.editing;
    this.notifications = rootStore.notifications;

    this.dataSource = dataSource;
    this.horizontalViewport = horizontalViewport;
    this.verticalViewport = verticalViewport;
    this.viewportController = viewportController;
    this.autoScrollController = autoScrollController;
    this.dataController = dataController;

    this.resolveConflict = resolveConflict;
    this.reloadData = reloadData;

    this.dndContext = new DndContextStore(horizontalViewport, verticalViewport, this.autoScrollController, {
      onMovingStart: () => this.startMoving(),
      onMovingFinish: () => this.finishMoving(),
      onDragMove: (active, over, interactive) => this.onDragMove(active, over, interactive),
      onDrop: (active, over, pointerPosition) => this.onDrop(active, over, pointerPosition),
      onDropError: () => showErrorMessage(this.i18.t('errors:invalidPosition')),
      onInteractiveOver: (interactive) => this.onInteractiveOver(interactive),
      getIsDraggingPositionAvailable: (pointerPosition, over) =>
        this.getIsDraggingPositionAvailable(pointerPosition, over),
      getIsDraggable: (item) => this.getIsDraggable(item),
      getIsElementDragging: (activeId, activeElement, activeDataItem, draggingItem) =>
        this.getIsElementDragging(activeId, activeElement, activeDataItem, draggingItem),
    });

    this.conflictSidebar = new ConflictSidebarStore(this.dndContext, rootStore);
    this.confirmationRemoveSidebar = new ConfirmationRemoveSidebarStore(rootStore);
    this.padsAndWellsSidebarStore = new PadsAndWellsSidebarStore(rootStore, this.dndContext);
    this.planRigOperationSidebar = new PlanRigOperationSidebarStore();
    this.confirmationRemoveSidebar = new ConfirmationRemoveSidebarStore(rootStore);

    this.addingRigScenario = new AddingRigScenario(
      this.padsAndWellsSidebarVisibilityController,
      this.planRigOperationSidebarVisibilityController
    );

    makeObservable(this);
  }

  @action.bound
  private onInteractiveOver(interactive: DraggingInteractive<RigOperationsDnd.DraggingInteractive>): void {
    if (interactive.dataItem instanceof RigsGroup) {
      if (interactive.dataItem.isCollapsed) {
        interactive.dataItem.setIsCollapsed(!interactive.dataItem.isCollapsed);
        this.dataController.recalculatePositions();

        return;
      }
    }
  }

  @action
  private onDragMove: DndContextStore.onDragMoveFn<
    RigOperationsDnd.DraggableItem,
    InsertionPoint<WellRigOperation>,
    RigOperationsDnd.DraggingInteractive,
    RigOperationsDnd.DraggableShadow
  > = (active, over, interactive): void => {
    if (!this.activeGhost) {
      return;
    }

    if (interactive?.dataItem instanceof InteractiveInsertionPoint) {
      if (!this.activeGhost.shadow) {
        const shadowDataItem = active.dataItem.getShadow?.();

        if (shadowDataItem) {
          const positionsCalculator = new RigsDataPositionsCalculator(
            RigsDataPositionsCalculator.EDITING_ELEMENT_SIZES_GETTERS
          );

          // 40 days
          const UNPLANNED_RIG_OPERATION_LENGTH = 40 * 24 * 60 * 60;

          const activeDraggableKey = this.activeGhost.ghost.getKey('draggable');
          const padY = positionsCalculator.calculatePadPosition(
            interactive.dataItem.y,
            DataView.DataViewType.full,
            false
          );

          const unplannedWells: ShadowWellRigOperation[] = [];
          let previousWell: ShadowWellRigOperation | undefined;

          for (const well of shadowDataItem.wells) {
            const rigOperationLength = (well.x?.end ?? 0) - (well.x?.start ?? 0);

            const getStart = (): number => {
              if (well.x) {
                return well.x.start;
              }

              if (previousWell?.x) {
                return previousWell.x.end + 1;
              }

              return active.dataItem.x?.start ?? 0;
            };

            const start = getStart();
            const end = start + (rigOperationLength || UNPLANNED_RIG_OPERATION_LENGTH);

            const wellY = positionsCalculator.calculateWellPosition(padY, DataView.DataViewType.full, false);

            const unplannedWell = new ShadowWellRigOperation(
              well,
              { start, end },
              {
                y: wellY,
              },
              activeDraggableKey
            );

            unplannedWells.push(unplannedWell);
            previousWell = unplannedWell;
          }

          const firstWell = unplannedWells.at(0);
          const lastWell = unplannedWells.at(-1);

          if (firstWell && lastWell) {
            const unplannedPadWrapper = new ShadowPadRigOperation(
              shadowDataItem,
              {
                start: firstWell.x.start,
                end: lastWell.x.end,
              },
              {
                y: padY,
              },
              activeDraggableKey
            );

            unplannedWells.forEach((well) => well.setParentPad(unplannedPadWrapper));

            this.activeGhost.addShadow(shadowDataItem, () => [unplannedPadWrapper, ...unplannedWells]);
          }
        }
      }
    } else {
      this.activeGhost.removeShadow();
    }
  };

  @action.bound
  private onScroll(x: number, y: number): void {
    const { containerRect } = this.dndContext;

    if (!containerRect) {
      return;
    }

    const xInMilliseconds = pixelsToMilliseconds(x, this.horizontalViewport, containerRect.width);

    this.horizontalViewport.add(xInMilliseconds);
    this.verticalViewport.add(y);
  }

  @action.bound
  confirmAddingRigStartDate(): void {
    this.addingRigScenario.addingChildElementStartDate?.resolve(this.planRigOperationSidebar.workStartDateSeconds);
    this.planRigOperationSidebar.setWorkStartDate(null);
  }

  @flow.bound
  async *removeRigOperationsList(
    rigOperationsList: PadRigOperation | RigOperation[],
    forceDelete?: boolean
  ): Promise<void> {
    const someRigOperation =
      rigOperationsList instanceof PadRigOperation
        ? rigOperationsList.wellRigOperations.at(0)
        : rigOperationsList.at(0);

    const rigId =
      someRigOperation instanceof WellRigOperation ? someRigOperation.parentRig.id : someRigOperation?.getRigId();

    const tripleId = someRigOperation?.getFieldValue('GOplan_PlanWellTriple.id') ?? null;

    if (!hasValue(tripleId) || !hasValue(rigId) || Number.isNaN(Number(tripleId))) {
      return;
    }

    try {
      const res = await this.dataController.removeRigOperationsList(Number(tripleId), rigId, forceDelete);
      yield;

      if (res.needConfirmation && res.affectedRigOperationList && res.deletingRigOperationList) {
        this.confirmationRemoveSidebar.onOpen(
          DELETING_OBJECT.rigOperationsList,
          res.deletingRigOperationList,
          res.affectedRigOperationList
        );
      }

      if (res.conflictList?.length) {
        this.conflictSidebar.onOpen(res.conflictList[0]);
      }
    } catch (e) {
      yield;
      console.error(e);

      this.notifications.showErrorMessageT('errors:removeRigOperationsList');
    }
  }

  @flow.bound
  async *removeRigOperation(
    rigOperation: WellRigOperation | ConflictRigOperation | RigOperation,
    forceDelete?: boolean
  ): Promise<void> {
    try {
      const tripleId = rigOperation.getFieldValue('GOplan_PlanWellTriple.id') ?? null;
      const rigId = rigOperation instanceof WellRigOperation ? rigOperation.parentRig.id : rigOperation.getRigId();

      if (hasValue(tripleId) && hasValue(rigId) && !Number.isNaN(Number(tripleId))) {
        const res = await this.dataController.removeRigOperation(Number(tripleId), rigId, forceDelete);
        yield;

        if (res.needConfirmation && res.affectedRigOperationList && res.deletingRigOperationList) {
          this.confirmationRemoveSidebar.onOpen(
            DELETING_OBJECT.rigOperation,
            res.deletingRigOperationList,
            res.affectedRigOperationList
          );
        }

        if (res.conflictList?.length) {
          this.conflictSidebar.onOpen(res.conflictList[0]);
        } else if (rigOperation instanceof ConflictRigOperation) {
          this.resolveConflict(true);
        }
      } else {
        this.notifications.showErrorMessageT('errors:removeRigOperation');
        this.resolveConflict(false);
      }
    } catch (e) {
      yield;
      console.error(e);

      this.notifications.showErrorMessageT('errors:removeRigOperation');
    }
  }

  @flow.bound
  private async *onDrop(
    active: DraggableActive<RigOperationsDnd.DraggableItem>,
    over: DroppableActive<InsertionPoint<WellRigOperation>>,
    position: Dnd.Coordinates
  ) {
    const { temporaryRig } = this;

    if (this.addingRigScenario.isRunning && temporaryRig) {
      this.planRigOperationSidebar.setWorkStartDate(position.x);

      if (active.dataItem instanceof SidebarWellRigOperation || active.dataItem instanceof SidebarPadRigOperation) {
        const shadowItems = this.activeGhost?.shadow?.getViewItems();
        const temporaryAddedItems: (TemporaryPadRigOperation | TemporaryWellRigOperation)[] = [];

        if (shadowItems) {
          for (const shadowItem of shadowItems) {
            if (shadowItem instanceof ShadowPadRigOperation && shadowItem.y.start && shadowItem.y.end) {
              const offset = position.x - shadowItem.x.start;
              const start = shadowItem.x.start + offset;
              const end = shadowItem.x.end + offset;

              const padClone = new TemporaryPadRigOperation(
                shadowItem.pad.pad,
                temporaryRig,
                { start, end },
                { start: shadowItem.y.start, end: shadowItem.y.end }
              );

              temporaryAddedItems.push(padClone);
              continue;
            }

            if (shadowItem instanceof ShadowWellRigOperation && shadowItem.parentPad) {
              const offset = position.x - shadowItem.parentPad.x.start;
              const start = shadowItem.x.start + offset;
              const end = shadowItem.x.end + offset;

              const wellClone = new TemporaryWellRigOperation(
                shadowItem.well.data,
                shadowItem.well.wellId,
                temporaryRig,
                { start, end },
                shadowItem.y
              );

              temporaryAddedItems.push(wellClone);
            }
          }
        }

        this.itemsInTemporaryRig = temporaryAddedItems;
        this.addingRigScenario.addingChildElement?.resolve(active.dataItem);
      }

      this.activeGhost = null;

      return;
    }

    if (
      active.dataItem instanceof WellRigOperation ||
      active.dataItem instanceof SidebarWellRigOperation ||
      active.dataItem instanceof ConflictRigOperation
    ) {
      const insertion = active.dataItem;

      const tripleIdFieldName = addGOplanPrefix('PlanWellTriple.id');
      const insertionId = insertion.getFieldValue(tripleIdFieldName);

      const insertAfterId = over.dataItem.left?.getFieldValue(tripleIdFieldName) ?? null;
      const insertOnPlaceId = over.dataItem.right?.getFieldValue(tripleIdFieldName) ?? null;

      const rigIdFieldName = addGOplanPrefix('RigOperation.rigId');

      const initialRigId =
        active.dataItem instanceof WellRigOperation
          ? active.dataItem.parentRig.id
          : Number(insertion.getFieldValue(rigIdFieldName));

      const targetRigId = over.dataItem.left?.parentRig.id ?? over.dataItem.right?.parentRig.id;

      if (!hasValue(insertionId) || !hasValue(targetRigId)) {
        return;
      }

      assert(typeof insertionId === 'number', `Invalid rig operation field value, field name: ${tripleIdFieldName}.`);

      if (hasValue(insertAfterId) || hasValue(insertOnPlaceId)) {
        assert(
          (typeof insertOnPlaceId === 'number' || insertOnPlaceId === null) &&
            (typeof insertAfterId === 'number' || insertAfterId === null),
          `Invalid rig operation field value, field name: ${tripleIdFieldName}.`
        );

        if (active.dataItem instanceof ConflictRigOperation) {
          this.conflictSidebar.onClose();
        }

        const res = await this.dataController.changeRigOperationsOrder({
          insertion: insertionId,
          ...(hasValue(insertAfterId) && { insertAfter: insertAfterId }),
          ...(hasValue(insertOnPlaceId) && { insertOnPlace: insertOnPlaceId }),
          targetRigId,
          initialRigId,
        });
        yield;

        if (active.dataItem instanceof SidebarWellRigOperation) {
          this.padsAndWellsSidebarStore.fetchPads();
        }

        if (isObjectWithKeys(res)) {
          this.conflictSidebar.onOpen(res);
        } else if (active.dataItem instanceof ConflictRigOperation) {
          this.resolveConflict(true);
        }

        return;
      }

      return;
    }

    if (active.dataItem instanceof PadRigOperation) {
      const someWellFromPad = active.dataItem.wellRigOperations.find((item) => item);

      if (!someWellFromPad) {
        return;
      }

      const tripleIdFieldName = addGOplanPrefix('PlanWellTriple.id');
      const insertionId = someWellFromPad.getFieldValue(tripleIdFieldName);

      const insertAfterId = over.dataItem.left?.getFieldValue(tripleIdFieldName) ?? null;
      const insertOnPlaceId = over.dataItem.right?.getFieldValue(tripleIdFieldName) ?? null;

      const initialRigId = active.dataItem.parentRig.id;
      const targetRigId = over.dataItem.left?.parentRig.id;

      if (!hasValue(insertionId) || !hasValue(targetRigId)) {
        return;
      }

      assert(typeof insertionId === 'number', `Invalid rig operation field value, field name: ${tripleIdFieldName}.`);

      if (hasValue(insertAfterId) || hasValue(insertOnPlaceId)) {
        assert(
          (typeof insertOnPlaceId === 'number' || insertOnPlaceId === null) &&
            (typeof insertAfterId === 'number' || insertAfterId === null),
          `Invalid rig operation field value, field name: ${tripleIdFieldName}.`
        );

        const res = await this.dataController.changeRigOperationsListOrder({
          insertion: insertionId,
          ...(hasValue(insertAfterId) && { insertAfter: insertAfterId }),
          ...(hasValue(insertOnPlaceId) && { insertOnPlace: insertOnPlaceId }),
          targetRigId,
          initialRigId,
        });
        yield;

        if (isObjectWithKeys(res)) {
          this.conflictSidebar.onOpen(res);
        }

        return;
      }
      return;
    }

    if (active.dataItem instanceof SidebarPadRigOperation) {
      const tripleIdFieldName = addGOplanPrefix('PlanWellTriple.id');

      const insertionId =
        active.dataItem.groupVariant === UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS.pro
          ? active.dataItem.wells.find((item) => item)?.getFieldValue(tripleIdFieldName)
          : active.dataItem.pad.id;

      if (!hasValue(insertionId)) {
        return;
      }

      const insertAfterId = over.dataItem.left?.getFieldValue(tripleIdFieldName) ?? null;
      const insertOnPlaceId = over.dataItem.right?.getFieldValue(tripleIdFieldName) ?? null;

      const rigIdFieldName = addGOplanPrefix('RigOperation.rigId');

      const initialRigId = Number(active.dataItem.firstWell?.getFieldValue(rigIdFieldName));
      const targetRigId = over.dataItem.left?.parentRig.id;

      if (!hasValue(insertionId) || !hasValue(targetRigId) || !hasValue(initialRigId)) {
        return;
      }

      assert(typeof insertionId === 'number', `Invalid rig operation field value, field name: ${tripleIdFieldName}.`);

      if (hasValue(insertAfterId) || hasValue(insertOnPlaceId)) {
        assert(
          (typeof insertOnPlaceId === 'number' || insertOnPlaceId === null) &&
            (typeof insertAfterId === 'number' || insertAfterId === null),
          `Invalid rig operation field value, field name: ${tripleIdFieldName}.`
        );

        if (active.dataItem.groupVariant === UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS.pad) {
          const res = await this.dataController.changePadsOrder({
            insertion: insertionId,
            ...(hasValue(insertAfterId) && { insertAfter: insertAfterId }),
            ...(hasValue(insertOnPlaceId) && { insertOnPlace: insertOnPlaceId }),
            targetRigId,
            initialRigId,
          });
          yield;

          if (isObjectWithKeys(res)) {
            this.conflictSidebar.onOpen(res);
          }

          this.padsAndWellsSidebarStore.fetchPads();
          return;
        }

        if (active.dataItem.groupVariant === UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS.pro) {
          const res = await this.dataController.changeRigOperationsListOrder({
            insertion: insertionId,
            ...(hasValue(insertAfterId) && { insertAfter: insertAfterId }),
            ...(hasValue(insertOnPlaceId) && { insertOnPlace: insertOnPlaceId }),
            targetRigId,
            initialRigId,
          });
          yield;

          if (isObjectWithKeys(res)) {
            this.conflictSidebar.onOpen(res);
          }

          this.padsAndWellsSidebarStore.fetchPads();
          return;
        }

        return;
      }

      return;
    }
  }

  private getIsDraggingPositionAvailable: DndContextStore.GetIsDraggingPositionAvailableFn<
    InsertionPoint<WellRigOperation>
  > = (pointerPosition, over): boolean => {
    const isTemporary = over?.dataItem instanceof InteractiveInsertionPoint;

    if (!isTemporary && over?.dataItem.isAvailable) {
      return true;
    }

    const now = DateHelper.dateToUnix(new Date());

    return pointerPosition.x > now;
  };

  private getIsDraggable: DndContextStore.GetIsDraggable<
    RigOperationsDnd.DraggableItem,
    RigOperationsDnd.DraggableShadow
  > = (item) => {
    const now = DateHelper.dateToUnix(new Date());

    if (item instanceof SidebarWellRigOperation) {
      return true;
    }

    if (item instanceof SidebarPadRigOperation) {
      return !item.x.start || item.x.start > now;
    }

    if (item instanceof WellRigOperation) {
      return hasValue(item.start) && item.start > now;
    }

    if (item instanceof PadRigOperation) {
      return item.x.start > now;
    }

    if (item instanceof ConflictRigOperation) {
      return true;
    }

    return false;
  };

  private getIsElementDragging: DndContextStore.GetIsElementDragging<
    RigOperationsDnd.DraggableItem,
    WellRigOperation,
    RigOperationsDnd.DraggableShadow
  > = (activeId, activeElement, activeDataItem, draggingItem): boolean => {
    if (activeDataItem instanceof WellRigOperation) {
      return activeDataItem === draggingItem.dataItem;
    }

    if (activeDataItem instanceof PadRigOperation) {
      return draggingItem.dataItem.parentPad?.getKey() === activeDataItem.getKey();
    }

    return false;
  };

  init(): VoidFunction {
    const disposeDndContext = this.dndContext.init();

    const disposePadsAndWellsSidebar = this.padsAndWellsSidebarStore.init();

    const disposeConflictSidebar = this.conflictSidebar.init();

    const disposeInsertionPointsCalculation = reaction(
      () => ({
        activeGhost: this.activeGhost,
        data: this.dataSource.data,
        activeShadow: this.activeGhost?.shadow,
        temporaryRig: this.temporaryRig,
      }),
      ({ activeGhost, data, activeShadow, temporaryRig }) => {
        if (data) {
          if (!activeGhost) {
            this.editingDataItems = data;
            return;
          }

          const editingDataItems: RigOperationsDnd.ViewItem[] = [activeGhost.parent, activeGhost];

          if (activeShadow) {
            editingDataItems.push(...activeShadow.getViewItems());
          }

          const { insertionPoints, rest: dataWithInsertionPoints } = temporaryRig
            ? InsertionPointsCalculator.getInsertionPointForTemporaryRig(data, activeGhost)
            : InsertionPointsCalculator.getDataWithInsertionPoints(data, activeGhost);

          this.editingDataItems = [...editingDataItems, ...insertionPoints, ...dataWithInsertionPoints];
        }
      }
    );

    const disposeScrollingToAddedRig = reaction(
      () => this.temporaryRig,
      (rig) => {
        if (rig?.y && hasValue(rig.y.start)) {
          const viewportHeight = this.verticalViewport.length;
          const rigHeight = rig.y.end - rig.y.start;
          const viewportRadius = viewportHeight / 2 - rigHeight / 2;
          const scrollOffset = rig.y.start - (this.verticalViewport.start + viewportRadius);

          const { movedStart, movedEnd } = moveViewport(this.verticalViewport, scrollOffset);
          this.verticalViewport.setRange(movedStart, movedEnd, { animate: true, duration: 500 });
        }
      }
    );

    return () => {
      disposeDndContext();
      disposePadsAndWellsSidebar();
      disposeConflictSidebar();
      disposeInsertionPointsCalculation();
      disposeScrollingToAddedRig();
    };
  }

  @action.bound
  private onAddingRigCancel(): void {
    showErrorMessage(
      'Процесс добавления буровой установки был прерван. ' +
        'Чтобы добавить БУ необходимо запланировать хотя бы один подход для нее.'
    );

    this.dataController.removeTemporaryRig();
    this.temporaryRig = null;
  }

  @computed
  get data(): RigsChartStore.ViewItem[] | null {
    const { data } = this.dataSource;

    if (!data) {
      return null;
    }

    if (this.isDisabled) {
      return data;
    }

    const { addingRigScenario, itemsInTemporaryRig, editingDataItems } = this;

    if (addingRigScenario.isRunning && itemsInTemporaryRig) {
      return [...itemsInTemporaryRig, ...editingDataItems];
    }

    if (!this.isMoving) {
      return data;
    }

    return editingDataItems;
  }

  get isLoading(): boolean {
    return this.dataSource.isLoading ?? false;
  }

  @action.bound
  startMoving(): void {
    if (!this.dndContext.active?.dataItem) {
      this.isMoving = false;
      return;
    }

    const { dataItem } = this.dndContext.active;

    if (RigOperationsDnd.isDraggableItem(dataItem)) {
      this.isMoving = true;
      this.activeGhost = new DraggableGhost<
        RigOperationsDnd.DraggableItem,
        RigOperationsDnd.DraggableShadow,
        RigsChartDataModel.ViewItem
      >(dataItem, dataItem.clone(), dataItem.getKey('draggable'));
    }
  }

  @action.bound
  finishMoving(): void {
    this.isMoving = false;

    const { start: viewportStart, end: viewportEnd } = this.horizontalViewport;
    const startNearestPoint = this.viewportController.getNearestPoint(viewportStart);
    const endNearestPoint = this.viewportController.getNearestPoint(viewportEnd);

    this.horizontalViewport.setRange(startNearestPoint, endNearestPoint - 1, { animate: true });

    this.activeGhost = null;
  }

  @flow.bound
  async *addTemporaryRig(rig: EmptyRig): Promise<void> {
    const planVersionId = this.editing.actualPlanVersionId;

    if (!planVersionId) {
      return;
    }

    this.temporaryRig = this.dataController.addTemporaryRig(rig.dataItem);

    try {
      const { addingElement, startDate } = await this.addingRigScenario.run();
      yield;

      if (!hasValue(startDate)) {
        return;
      }

      if (addingElement instanceof SidebarPadRigOperation) {
        const tripleIdFieldName = addGOplanPrefix('PlanWellTriple.id');

        const insertionId =
          addingElement.groupVariant === UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS.pro
            ? addingElement.wells.find((item) => item)?.getFieldValue(tripleIdFieldName)
            : addingElement.pad.id;

        if (!hasValue(insertionId)) {
          return;
        }

        assert(typeof insertionId === 'number', `Invalid rig operation field value, field name: ${tripleIdFieldName}.`);

        if (addingElement.groupVariant === UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS.pad) {
          const res = await this.dataController.changePadsOrder({
            insertion: insertionId,
            insertAfter: undefined,
            insertOnPlace: undefined,
            targetRigId: rig.id,
            startDate,
          });
          yield;

          if (isObjectWithKeys(res)) {
            this.conflictSidebar.onOpen(res);
          }
        }

        if (addingElement.groupVariant === UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS.pro) {
          const res = await this.dataController.changeRigOperationsListOrder({
            insertion: insertionId,
            insertAfter: undefined,
            insertOnPlace: undefined,
            targetRigId: rig.id,
            startDate,
          });
          yield;

          if (isObjectWithKeys(res)) {
            this.conflictSidebar.onOpen(res);
          }
        }
      }

      if (addingElement instanceof SidebarWellRigOperation) {
        const tripleIdFieldName = addGOplanPrefix('PlanWellTriple.id');
        const insertionId = addingElement.getFieldValue(tripleIdFieldName);

        if (!hasValue(insertionId)) {
          return;
        }

        assert(typeof insertionId === 'number', `Invalid rig operation field value, field name: ${tripleIdFieldName}.`);

        const res = await this.dataController.changeRigOperationsOrder({
          insertion: insertionId,
          insertAfter: undefined,
          insertOnPlace: undefined,
          targetRigId: rig.id,
          initialRigId: undefined,
          startDate,
        });
        yield;

        if (isObjectWithKeys(res)) {
          this.conflictSidebar.onOpen(res);
        }
      }

      this.padsAndWellsSidebarStore.fetchPads();

      await this.reloadData(planVersionId);
      yield;
    } catch (e) {
      yield;

      if (e instanceof AddingRigCancellationError) {
        this.onAddingRigCancel();
      } else {
        console.error(e);

        this.notifications.showErrorMessageT('errors:failedToAddRigOrApproachPlanning');
      }
    } finally {
      this.temporaryRig = null;
      this.itemsInTemporaryRig = null;
      this.dataController.removeTemporaryRig();
    }
  }

  @action.bound
  disable(): void {
    this.isDisabled = true;
  }

  @action.bound
  enable(): void {
    this.isDisabled = false;
  }
}

export namespace RigOperationsDnd {
  export type DraggableItem =
    | WellRigOperation
    | PadRigOperation
    | ConflictRigOperation
    | SidebarWellRigOperation
    | SidebarPadRigOperation;

  export type DraggableShadow = SidebarPadRigOperation;

  export type DraggingInteractive = RigsGroup | InteractiveInsertionPoint;

  export type DndContext = DndContextStore<
    DraggableItem,
    InsertionPoint<WellRigOperation>,
    DraggingInteractive,
    WellRigOperation,
    DraggableShadow
  >;

  export type ViewItem =
    | RigsChartDataModel.ViewItem
    | InsertionPoint<WellRigOperation>
    | DraggableGhost<DraggableItem, DraggableShadow>
    | Dnd.Draggable<DraggableItem, DraggableShadow>
    | InsertionPointNeighbor<DraggableItem>
    | DraggableGhostShadow<DraggableItem, DraggableShadow, ViewItem>
    | ShadowWellRigOperation
    | ShadowPadRigOperation;

  export type ReloadDataFn = (planVersionId: number) => void;

  export const isDraggableItem = (item: unknown): item is DraggableItem => {
    if (item instanceof WellRigOperation) {
      return true;
    }

    if (item instanceof SidebarWellRigOperation) {
      return true;
    }

    if (item instanceof SidebarPadRigOperation) {
      return true;
    }

    if (item instanceof PadRigOperation) {
      return true;
    }

    if (item instanceof ConflictRigOperation) {
      return true;
    }

    return false;
  };

  export const isWellDraggableGhost = (
    item: unknown
  ): item is DraggableGhost<WellRigOperation | SidebarWellRigOperation> => {
    if (item instanceof DraggableGhost) {
      if (
        item.ghost instanceof SidebarWellRigOperation ||
        (item.ghost instanceof WellRigOperation && item.parent instanceof WellRigOperation)
      ) {
        return true;
      }
    }

    return false;
  };

  export const isPadDraggingShadow = (
    item: unknown
  ): item is
    | DraggableGhostShadow<SidebarWellRigOperation, SidebarPadRigOperation, SidebarWellRigOperation>
    | DraggableGhostShadow<WellRigOperation, PadRigOperation, WellRigOperation> => {
    if (item instanceof DraggableGhostShadow) {
      if (item.shadow instanceof SidebarPadRigOperation || item.shadow instanceof PadRigOperation) {
        return true;
      }
    }

    return false;
  };

  export const isPadDraggableGhost = (
    item: unknown
  ): item is DraggableGhost<PadRigOperation | SidebarPadRigOperation> => {
    if (item instanceof DraggableGhost) {
      if (
        item.ghost instanceof SidebarPadRigOperation ||
        (item.ghost instanceof PadRigOperation && item.parent instanceof PadRigOperation)
      ) {
        return true;
      }
    }

    return false;
  };

  export const isConflictWellDraggableGhost = (item: unknown): item is DraggableGhost<ConflictRigOperation> => {
    return (
      item instanceof DraggableGhost &&
      item.ghost instanceof ConflictRigOperation &&
      item.parent instanceof ConflictRigOperation
    );
  };
}
