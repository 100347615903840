import { action, autorun, comparer, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { RigsChartApi } from 'src/api/chart/rigs-chart-api';
import { RigsChartDataApi } from 'src/api/chart/rigs-chart-data-api';
import { SummaryDataApi } from 'src/api/chart/summary-data-api';
import { ConflictApi } from 'src/api/draft/conflict-api';
import { BaseApiError } from 'src/errors';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { debounce } from 'src/shared/utils/debounce';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { createPromiseController, TPromiseController } from 'src/shared/utils/promise-controller';
import { RootStore } from 'src/store';

import { AutoScrollController } from '../../features/auto-scroll/auto-scroll-controller';
import { DataHeadersPresenter } from '../../features/data-headers/presenter/data-headers-presenter';
import { DataItemsBackgroundPresenter } from '../../features/data-items-background/presenter';
import { DataItemsFullPresenter } from '../../features/data-items-full/presenter/data-items-full-presenter';
import { IndicatorsTableStore } from '../../features/indicators-table/indicators-table.store';
import { RigsChartDataModel } from '../../features/rigs-chart/data/rigs-chart-data-model';
import { RigsChartDataStorage } from '../../features/rigs-chart/data/rigs-chart-data-storage';
import { RigsSorting, RigsSortingModule } from '../../features/rigs-sorting/rigs-sorting';
import { SummaryDataModel } from '../../features/summary-data/data/summary-data-model';
import { SummaryDataPresenter } from '../../features/summary-data/presenter/summary-data-presenter';
import { ChartGroupType } from '../../shared/chart-group-type';
import { ChartGrouping } from '../../shared/chart-grouping';
import { DataView } from '../../shared/data-view/data-view';
import { ChartFiltersForm, FiltersFormStore } from '../../shared/filters-form.store';
import { RigsDataPositionsCalculator } from '../../shared/rigs-data-positions-calculator';
import {
  generateKeyFromRange,
  parseStringToRange,
  StorageKeyManagerWithParser,
} from '../../shared/storage-key-manager';
import { SummaryViewProvider } from '../../shared/summary-view-provider';
import { TimelineController } from '../../shared/timeline-controller';
import { Viewport } from '../../shared/viewport/viewport';
import { RigOperationsDnd, RigOperationsDndModule } from '../rig-operations-dnd/rig-operations-dnd-module';
import { SummaryDataSidebarStore } from '../summary-data-sidebar/summary-data-sidebar.store';

import { RigsGroupsAdapter } from './rigs-groups-adapter';
import { RigsViewSettingsStore } from './rigs-view-settings.store';

export class RigsChartStore {
  private readonly rootStore: RootStore;
  private readonly dataView: DataView;
  private readonly chartDataModel: RigsChartDataModel;
  private readonly api: RigsChartApi;
  private readonly grouping: ChartGrouping;
  private readonly horizontalViewportController: TimelineController;
  private readonly autoScrollController: AutoScrollController;

  private conflictsResolverController: TPromiseController<boolean> | null = null;

  readonly horizontalViewport;
  readonly verticalViewport;
  readonly viewSettings: RigsViewSettingsStore;

  readonly dataHeadersPresenter: DataHeadersPresenter<RigsChartStore.ViewItem[] | null>;
  readonly dataItemsPresenter: DataItemsFullPresenter<RigsChartDataModel.ViewItem[] | null>;
  readonly dataItemsBackgroundPresenter;

  readonly summaryDataModel: SummaryDataModel;
  readonly summaryDataPresenter: SummaryDataPresenter;
  readonly summaryDataSidebar: SummaryDataSidebarStore;

  readonly indicators: IndicatorsTableStore;

  @observable isLoading = false;
  /** Use for transparent loaders. */
  @observable isDataUpdating = false;
  @observable searchTerm = '';
  @observable.ref filtersForm?: FiltersFormStore;
  @observable.ref summaryViewProvider?: SummaryViewProvider;

  @observable.ref rigsSortingModule: RigsSortingModule;
  @observable.ref rigOperationsDndModule: RigOperationsDndModule;

  constructor(
    horizontalViewport: Viewport,
    dataView: DataView,
    rootStore: RootStore,
    viewSettings: RigsViewSettingsStore,
    horizontalViewportController: TimelineController,
    grouping: ChartGrouping
  ) {
    this.rootStore = rootStore;

    this.viewSettings = viewSettings;
    this.dataView = dataView;
    this.horizontalViewport = horizontalViewport;
    this.verticalViewport = new Viewport(0, 0);
    this.grouping = grouping;
    this.horizontalViewportController = horizontalViewportController;
    this.autoScrollController = new AutoScrollController(this.horizontalViewport, this.verticalViewport);

    this.api = new RigsChartApi();

    this.chartDataModel = new RigsChartDataModel(
      this.verticalViewport,
      horizontalViewport,
      dataView,
      new RigsChartDataApi(rootStore),
      new ConflictApi(),
      new RigsChartDataStorage(
        new StorageKeyManagerWithParser(generateKeyFromRange, parseStringToRange),
        this.dataView,
        new RigsDataPositionsCalculator(RigsDataPositionsCalculator.DEFAULT_ELEMENT_SIZES_GETTERS)
      ),
      rootStore
    );
    this.dataHeadersPresenter = new DataHeadersPresenter(this.verticalViewport, this.chartDataModel, {
      onGroupCollapsedStateChange: () => this.updateRigsSettings(),
    });
    this.dataItemsBackgroundPresenter = new DataItemsBackgroundPresenter(
      this.verticalViewport,
      horizontalViewport,
      this.chartDataModel,
      this.horizontalViewportController
    );
    this.dataItemsPresenter = new DataItemsFullPresenter(this.chartDataModel);

    this.summaryDataModel = new SummaryDataModel(
      new SummaryDataApi(),
      this.rootStore.editing,
      this.rootStore.notifications
    );
    this.summaryDataPresenter = new SummaryDataPresenter(this.verticalViewport, this.summaryDataModel);
    this.summaryDataSidebar = new SummaryDataSidebarStore();

    this.indicators = new IndicatorsTableStore(
      this.horizontalViewport,
      this.rootStore.editing,
      this.rootStore.notifications
    );

    // --> Data is passed through each module (as DataSource, first parameter in constructor) from the data model to the view.
    //     Data flow: RigsChartDataModel.data -> RigOperationsDndModule.data -> RigsSortingModule.data -> this.data.
    this.rigOperationsDndModule = new RigOperationsDndModule(
      this.chartDataModel,
      this.horizontalViewport,
      this.verticalViewport,
      this.horizontalViewportController,
      this.autoScrollController,
      rootStore,
      this.resolveConflictController,
      this.chartDataModel,
      (planVersionId) => this.loadChartData(planVersionId)
    );

    this.rigsSortingModule = new RigsSortingModule(
      this.rigOperationsDndModule,
      this.rootStore.notifications,
      this.verticalViewport,
      new RigsDataPositionsCalculator(
        this.rootStore.editing.isEditing
          ? RigsDataPositionsCalculator.EDITING_ELEMENT_SIZES_GETTERS
          : RigsDataPositionsCalculator.DEFAULT_ELEMENT_SIZES_GETTERS
      ),
      this.dataView,
      this.autoScrollController,
      (current, placeOn, placement) => this.updateRigsOrder(current, placeOn, placement),
      (current, placeOn, placement) => this.updateRigGroupsOrder(current, placeOn, placement),
      () => this.saveRigsOrder()
    );
    // <--

    makeObservable(this);
  }

  @computed
  private get planVersionId(): number {
    const planVersionId = this.rootStore.editing.actualPlanVersionId;

    assert(hasValue(planVersionId), 'Invalid plan version ID.');

    return planVersionId;
  }

  @computed({ equals: comparer.structural })
  private get filtersFormValues(): { filter: ChartFiltersForm.FilterValues; grouping: ChartGroupType } {
    return {
      filter: this.filtersForm?.formValues?.filter || {},
      grouping: this.grouping.value,
    };
  }

  private async getDataLength(
    filter: ChartFiltersForm.FilterValues,
    grouping: ChartGroupType | null,
    cancellationSignal?: AbortSignal
  ): Promise<number> {
    const planVersionId = this.planVersionId;

    if (!grouping) {
      throw new Error('Invalid chart grouping type.');
    }

    const rigsGroups = await this.api.getRigs(planVersionId, grouping, filter, this.searchTerm, {
      cancellationSignal,
    });

    if (!rigsGroups) {
      return 0;
    }

    return rigsGroups.reduce((rigsNumber: number, rigsGroup) => rigsNumber + rigsGroup.items.length, 0);
  }

  private async search(): Promise<void> {
    this.loadChartData(this.planVersionId);
  }

  private onSearch = debounce(this.search.bind(this), 400);

  private updateRigsOrder: RigsSorting.OnRigsSortFn = (current, placeOn, placement): void => {
    try {
      this.chartDataModel.changeRigsOrder(current, placeOn, placement);
    } catch (e) {
      console.error(e);
      this.rootStore.notifications.showErrorMessageT('errors:failedToChangeItemsOrder');
    }
  };

  private updateRigGroupsOrder: RigsSorting.OnRigGroupsSortFn = (current, placeOn, placement): void => {
    try {
      this.chartDataModel.changeRigGroupsOrder(current, placeOn, placement);
    } catch (e) {
      console.error(e);
      this.rootStore.notifications.showErrorMessageT('errors:failedToChangeItemsOrder');
    }
  };

  private saveRigsOrder = (): void => {
    try {
      const { rigGroups } = this.chartDataModel;

      if (rigGroups) {
        this.viewSettings.updateRigsAndGroupsSettingsThrottled(rigGroups, this.grouping);
      }
    } catch (e) {
      console.error(e);
      this.rootStore.notifications.showErrorMessageT('errors:failedToChangeItemsOrder');
    }
  };

  @flow.bound
  private async *loadFilters(planVersionId: number): Promise<void> {
    try {
      const view = await this.rootStore.views.rigsChartFiltersView.loadView();
      yield;

      this.filtersForm = new FiltersFormStore(
        planVersionId,
        view,
        this.rootStore,
        this.onFiltersChange.bind(this),
        this.getDataLength.bind(this),
        this.grouping
      );

      await this.filtersForm.loadData();
    } catch (e) {
      yield;

      console.error(e);
      this.rootStore.notifications.showErrorMessageT('errors:failedToLoadFilters');
    }
  }

  private updateRigsSettings(): void {
    if (this.chartDataModel.rigGroups) {
      this.viewSettings.updateRigsAndGroupsSettingsThrottled(this.chartDataModel.rigGroups, this.grouping);
    }
  }

  @computed
  get hasSelectedFilters(): boolean {
    return Object.values(this.filtersFormValues.filter).filter((fieldValue) => hasValue(fieldValue)).length > 0;
  }

  @computed
  get isGroupsCollapsed(): boolean {
    if (this.chartDataModel.rigGroups) {
      for (const rigGroup of this.chartDataModel.rigGroups) {
        if (rigGroup.isCollapsed) {
          return true;
        }
      }
    }

    return false;
  }

  @action.bound
  toggleGroupsIsCollapsed(): void {
    const { isGroupsCollapsed } = this;

    this.chartDataModel.rigGroups?.forEach((group) => {
      group.setIsCollapsed(!isGroupsCollapsed);
    });
    this.chartDataModel.recalculatePositions();
    this.updateRigsSettings();
  }

  @action.bound
  onSearchChange(searchTerm: string): void {
    this.searchTerm = searchTerm;

    this.onSearch();
  }

  @flow.bound
  async *loadChartData(planVersionId: number) {
    try {
      this.isDataUpdating = true;

      const { filter, grouping } = this.filtersFormValues;

      const rigsGroups = await this.api.getRigs(planVersionId, grouping, filter, this.searchTerm);
      yield;

      if (rigsGroups) {
        const rigsOrderByCurrentGrouping = this.viewSettings.view.ownSettingsValues.rigsOrder?.find(
          (entry) => entry.grouping === this.grouping.value
        )?.settings;

        const previousRigGroups = this.chartDataModel.rigGroups;

        const initializedRigGroups = RigsGroupsAdapter.init(rigsGroups, previousRigGroups);

        this.chartDataModel.setRigs(RigsGroupsAdapter.applySettings(initializedRigGroups, rigsOrderByCurrentGrouping));
      }
    } catch (e) {
      yield;

      console.error(e);

      if (e instanceof BaseApiError && e.responseMessage) {
        this.rootStore.notifications.showErrorMessage(e.responseMessage);
        return;
      }

      this.rootStore.notifications.showErrorMessageT('errors:failedToLoadChart');
    } finally {
      this.isDataUpdating = false;
    }
  }

  async onFiltersChange(): Promise<void> {
    await this.loadChartData(this.planVersionId);
  }

  @action.bound
  private resolveConflictController(isResolved: boolean): void {
    this.conflictsResolverController?.resolve(isResolved);
  }

  @flow.bound
  async *checkConflicts(): Promise<boolean> {
    this.conflictsResolverController = createPromiseController<boolean>();

    try {
      const res = await this.chartDataModel.checkConflicts();
      yield;

      if (!isObjectWithKeys(res)) {
        this.resolveConflictController(true);

        return await this.conflictsResolverController;
      }

      this.rigOperationsDndModule.conflictSidebar.onOpen(res);

      return await this.conflictsResolverController;
    } catch (e) {
      console.error(e);
      this.resolveConflictController(false);
    }
  }

  @computed
  get data(): RigsChartStore.ViewItem[] | null {
    return this.rigsSortingModule.data;
  }

  @action.bound
  init(): VoidFunction {
    const disposeViewSettings = this.viewSettings.init();

    const disposeVerticalScrollMovement = reaction(
      () => ({ verticalDataRange: this.chartDataModel.verticalDataRange, dataViewType: this.dataView.type }),
      ({ verticalDataRange, dataViewType }, { verticalDataRange: prevVerticalDataRange }) => {
        Viewport.moveViewportToPercentagePosition(
          this.verticalViewport,
          verticalDataRange,
          prevVerticalDataRange,
          this.dataHeadersPresenter.dataViewHeight
        );
      }
    );

    const disposeVerticalScrollLimitsUpdate = reaction(
      () => ({
        verticalViewport: this.verticalViewport,
        verticalDataRange: this.chartDataModel.verticalDataRange,
        dataViewHeight: this.dataHeadersPresenter.dataViewHeight,
      }),
      ({ verticalViewport, verticalDataRange, dataViewHeight }) => {
        Viewport.updateVerticalViewportLimits(verticalViewport, verticalDataRange, dataViewHeight);
      }
    );

    const disposeModelVerticalViewportRange = autorun(() => {
      const { start, end } = this.verticalViewport;
      this.chartDataModel.setVerticalRange(start, end);
    });

    const disposeModelHorizontalViewportRange = autorun(() => {
      const { start, end } = this.horizontalViewport;
      this.chartDataModel.setHorizontalRange(start, end);
    });

    const disposeDataModel = this.chartDataModel.init();

    const disposeShownWellAttributesNumberSetter = reaction(
      () => this.viewSettings.view.shownWellAttributesNumber,
      (shownWellAttributesNumber) => {
        this.chartDataModel.setShownWellAttributesNumber(shownWellAttributesNumber);
      },
      { fireImmediately: true }
    );

    const disposeSummaryDataModel = this.summaryDataModel.init();

    const disposeSummaryRigsSetter = reaction(
      () => ({ rigsInView: this.chartDataModel.getRigsInView(), summaryIsOpen: this.summaryDataSidebar.isOpen }),
      ({ rigsInView, summaryIsOpen }) => {
        if (summaryIsOpen && rigsInView) {
          this.summaryDataModel.setParentIdsList(rigsInView);
        }
      }
    );

    const disposeSummaryViewProvider = reaction(
      () => this.viewSettings.view.view.summary,
      (summary) => {
        this.summaryViewProvider = new SummaryViewProvider(summary, this.rootStore.directories);
      },
      { fireImmediately: true }
    );

    const disposeCalculationSettingsSetter = reaction(
      () => ({ isEditing: this.rootStore.editing.isEditing }),
      ({ isEditing }) => {
        if (isEditing) {
          this.chartDataModel.setChartSettings({
            calculation: RigsDataPositionsCalculator.EDITING_ELEMENT_SIZES_GETTERS,
          });
        } else {
          this.chartDataModel.setChartSettings({
            calculation: RigsDataPositionsCalculator.DEFAULT_ELEMENT_SIZES_GETTERS,
          });
        }
      },
      { fireImmediately: true }
    );

    const disposeFilterSetter = reaction(
      () => this.filtersFormValues,
      ({ filter, grouping }) => {
        this.chartDataModel.setFiltersAndGrouping(filter, grouping);
        this.indicators.onFiltersChange(filter);
      },
      { fireImmediately: true }
    );

    const disposeIndicators = this.indicators.init();

    const disposeDataReloading = reaction(
      () => this.planVersionId,
      (planVersionId) => {
        this.loadChartData(planVersionId);
        this.loadFilters(planVersionId);
      },
      { fireImmediately: true }
    );

    const disposeVerticalViewport = this.verticalViewport.init();

    const disposeRigsSortingModule = this.rigsSortingModule.init();

    const disposeAutoScrollController = this.autoScrollController.init();

    const disposeModulesSwitcher = reaction(
      () => ({
        isEditing: this.rootStore.editing.isEditing,
        dataViewType: this.dataView.type,
      }),
      ({ isEditing, dataViewType }) => {
        if (isEditing && dataViewType === DataView.DataViewType.full) {
          this.rigOperationsDndModule.enable();
        } else {
          this.rigOperationsDndModule.disable();
        }
      }
    );

    return () => {
      disposeModelVerticalViewportRange();
      disposeModelHorizontalViewportRange();
      disposeVerticalScrollLimitsUpdate();
      disposeVerticalScrollMovement();
      disposeDataModel();
      disposeShownWellAttributesNumberSetter();
      disposeSummaryDataModel();
      disposeSummaryRigsSetter();
      disposeSummaryViewProvider();
      disposeCalculationSettingsSetter();
      disposeFilterSetter();
      disposeViewSettings();
      disposeIndicators();
      disposeDataReloading();
      disposeVerticalViewport();
      disposeRigsSortingModule();
      disposeAutoScrollController();
      disposeModulesSwitcher();
    };
  }
}

export namespace RigsChartStore {
  export type ViewItem = RigsSorting.ViewItem | RigOperationsDnd.ViewItem;
}
