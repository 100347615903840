import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { ReactComponent as SettingsIcon } from 'src/shared/assets/icons/gear.svg';
import { ReactComponent as EditIcon } from 'src/shared/assets/icons/pencil.svg';
import { Tooltip } from 'src/shared/components/tooltip';

import styles from './view-mode-buttons.module.scss';

interface Props {
  pageStore: WellsPageStore;
}

export const ViewModeButtons = observer(function ViewModeButtons({ pageStore }: Props) {
  const { t } = useTranslation();

  // Временно отключенный функционал
  // function onOpenHistorySidebar(): void {
  //   pageStore.setIsHistorySidebarOpened(true);
  // }

  function onOpenViewSettingsSidebar(): void {
    pageStore.setIsViewSettingsSidebarOpened(true);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonsContainer}>
        {pageStore.isCanEditing && (
          <Tooltip text={t('common:edit')} placement="bottomRight">
            <button className={styles.button} onClick={pageStore.enableEditing}>
              <EditIcon />
            </button>
          </Tooltip>
        )}

        {/* Временно отключенный функционал */}
        {/* <Tooltip text={t('common:historyOfChange')} placement="bottomRight">
          <button className={styles.button} onClick={onOpenHistorySidebar}>
            <HistoryIcon />
          </button>
        </Tooltip> */}

        <Tooltip text={t('common:displaySettings')} placement="bottomRight">
          <button className={styles.button} onClick={onOpenViewSettingsSidebar}>
            <SettingsIcon />
          </button>
        </Tooltip>
      </div>
    </div>
  );
});
