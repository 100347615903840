import { hasValue } from './common';

export const getNumberWithNumberOfDecimalPlaces = (
  value: number | string,
  isInteger: boolean,
  numberOfDecimalPlaces?: number
): string | null => {
  if (Number.isNaN(Number(value))) {
    return null;
  }

  if ((!value && value !== 0) || Number.isNaN(Number(value))) return null;

  //Данные преобразования нужны для сокращения больших дробей до 6 знаков и отбрасывания ненужных нулей в конце
  if (isInteger) {
    return Number(Math.trunc(Number(value)).toFixed(0)).toString();
  }

  if (hasValue(numberOfDecimalPlaces)) {
    return Number(Number(value).toFixed(numberOfDecimalPlaces)).toString();
  }

  return null;
};
