import { observable, makeObservable } from 'mobx';

import { assert } from 'src/shared/utils/assert';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { TRigOperationStage } from '../../graph-info-sidebar';

import { Section } from './section.entity';

export class Stage {
  private readonly item: Record<string, unknown>;
  private readonly view: TRigOperationStage;
  private readonly directories: Directories;
  private readonly notifications: NotificationsStore;

  @observable sections: Section[] = [];
  readonly id: number;

  constructor(
    data: Record<string, unknown>,
    view: TRigOperationStage,
    directories: Directories,
    notifications: NotificationsStore,
    id: number
  ) {
    this.item = data;
    this.view = view;
    this.directories = directories;
    this.notifications = notifications;
    this.id = id;

    this.initializeSections();

    makeObservable(this);
  }

  private initializeSections(): void {
    const sectionsData = this.item[this.view.sections.objectName];
    const sectionsView = this.view.sections;

    if (!this.isSections(sectionsData)) {
      return;
    }

    sectionsData.forEach((rawSection) => {
      const id = rawSection[sectionsView.attrName];

      assert(
        typeof id === 'number' || (typeof id === 'string' && !Number.isNaN(Number(id))),
        'id is not presented in section'
      );

      const section = new Section(rawSection, sectionsView, this.directories, this.notifications, Number(id));

      this.sections.push(section);
    });
  }

  private isSections(rawSections: unknown): rawSections is Record<string, unknown>[] {
    if (!Array.isArray(rawSections) || rawSections.some((sec) => typeof sec !== 'object' || sec === null)) {
      return false;
    }

    return true;
  }

  get start(): number | null {
    const startDate = this.item['startDate'];

    if (typeof startDate === 'number' || (typeof startDate === 'string' && !Number.isNaN(Number(startDate)))) {
      return Number(startDate);
    }

    return null;
  }

  get end(): number | null {
    const endDate = this.item['endDate'];

    if (typeof endDate === 'number' || (typeof endDate === 'string' && !Number.isNaN(Number(endDate)))) {
      return Number(endDate);
    }

    return null;
  }

  get duration(): number | null {
    const duration = this.item['duration'];

    if (typeof duration === 'number' || (typeof duration === 'string' && !Number.isNaN(Number(duration)))) {
      return Number(duration);
    }

    return null;
  }

  get distance(): number | null {
    const distance = this.item['distance'];

    if (typeof distance === 'number' || (typeof distance === 'string' && !Number.isNaN(Number(distance)))) {
      return Number(distance);
    }

    return null;
  }

  get title(): string {
    const { refObjectType, refObjectAttr } = this.view;

    if (refObjectAttr && refObjectType) {
      const refObject = this.directories.getObject(refObjectType);
      const labelObject = refObject?.find(({ id }) => id.toString() === this.id.toString());
      const label = labelObject?.data[refObjectAttr];

      return label ? label.toString() : '';
    }

    return this.id.toString() || '';
  }
}
