import { action, flow, makeObservable, observable, reaction } from 'mobx';

import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { UserSettingsManager } from 'src/api/user-settings';
import { TimeUnit } from 'src/features/drilling-chart/shared/time-unit';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { RootStore } from 'src/store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { WellFormManagerWithGeoTasksHistory } from 'src/store/well-form-manager/well-form-manager-with-geo-tasks-history';

import {
  HeaderDataModel,
  HeaderDataModel as HeaderModel,
} from '../../features/drilling-chart/features/header/data/header-data-model';
import { DEFAULT_TIME_RANGE } from '../../features/drilling-chart/features/header/data/header-data-model.constants';
import { HeaderPresenter } from '../../features/drilling-chart/features/header/presenter/header-presenter';
import { IndicatorsTableSettingsStore } from '../../features/drilling-chart/features/indicators-table/indicators-table-settings.store';
import { TimelinePeriodsTogglePresenter } from '../../features/drilling-chart/features/timeline-periods-toggle/presenter';
import { TimelineDataApi } from '../../features/drilling-chart/features/timeline/data/timeline-data-api';
import { TimelineDataModel } from '../../features/drilling-chart/features/timeline/data/timeline-data-model';
import { TimelinePresenter } from '../../features/drilling-chart/features/timeline/presenter/timeline-presenter';
import { ComparingRigsChartStore } from '../../features/drilling-chart/presets/comparing-drilling-rigs-chart';
import { ComparingWellsChartStore } from '../../features/drilling-chart/presets/comparing-drilling-wells-chart';
import { RigsChartView } from '../../features/drilling-chart/presets/drilling-rigs-chart/rigs-chart-view';
import { RigsChartStore } from '../../features/drilling-chart/presets/drilling-rigs-chart/rigs-chart.store';
import { RigsViewSettingsStore } from '../../features/drilling-chart/presets/drilling-rigs-chart/rigs-view-settings.store';
import { WellsChartView } from '../../features/drilling-chart/presets/drilling-wells-chart/wells-chart-view';
import { WellsChartStore } from '../../features/drilling-chart/presets/drilling-wells-chart/wells-chart.store';
import { WellsViewSettingsStore } from '../../features/drilling-chart/presets/drilling-wells-chart/wells-view-settings.store';
import { DEFAULT_WELLS_CHART_GROUPING, isChartGroupType } from '../../features/drilling-chart/shared/chart-group-type';
import { ChartGrouping } from '../../features/drilling-chart/shared/chart-grouping';
import { DataView } from '../../features/drilling-chart/shared/data-view/data-view';
import { PlanType } from '../../features/drilling-chart/shared/plan-type';
import { TimelineController } from '../../features/drilling-chart/shared/timeline-controller';
import { Viewport } from '../../features/drilling-chart/shared/viewport/viewport';

import { HeaderStore } from './header.store';

export class DrillingPlanChartStore {
  private readonly rootStore: RootStore;
  private readonly timelineApi: TimelineDataApi;
  private readonly timelineDataModel: TimelineDataModel;
  private readonly headerModel: HeaderDataModel;
  private readonly wellsViewSettingsManager: UserSettingsManager<ViewSettings.RawSettingsValues>;
  private readonly rigsViewSettingsManager: UserSettingsManager<ViewSettings.RawSettingsValues>;
  private readonly notifications: NotificationsStore;
  private readonly timeUnit: TimeUnit;
  private readonly horizontalViewportController: TimelineController;
  private readonly wellsGrouping: ChartGrouping;

  @observable private rigsGrouping?: ChartGrouping;

  readonly horizontalViewport: Viewport;
  readonly timelineViewport: Viewport;

  readonly dataView: DataView;

  readonly timelinePeriodsTogglePresenter: TimelinePeriodsTogglePresenter;

  readonly timelineDataRange: Viewport;
  readonly timelinePresenter: TimelinePresenter;

  readonly headerPresenter: HeaderPresenter;

  readonly wellFormManager: WellFormManagerWithGeoTasksHistory;

  readonly indicatorsSettings: IndicatorsTableSettingsStore;

  readonly header: HeaderStore;

  @observable.ref rigsViewSettings?: RigsViewSettingsStore;
  @observable.ref rigsChartStore?: RigsChartStore;
  @observable.ref comparingRigsChartStore?: ComparingRigsChartStore;

  @observable.ref wellsViewSettings?: WellsViewSettingsStore;
  @observable.ref wellsChartStore?: WellsChartStore;
  @observable.ref comparingWellsChartStore?: ComparingWellsChartStore;

  @observable isChartLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.notifications = rootStore.notifications;
    this.wellFormManager = new WellFormManagerWithGeoTasksHistory(rootStore);
    this.timelineApi = new TimelineDataApi();

    this.header = new HeaderStore(rootStore.editing, rootStore.notifications, this);

    this.wellsViewSettingsManager = new UserSettingsManager<ViewSettings.RawSettingsValues>('wellsGraph', {});
    this.rigsViewSettingsManager = new UserSettingsManager<ViewSettings.RawSettingsValues>('rigsGraph', {});

    this.horizontalViewport = new Viewport(DEFAULT_TIME_RANGE.start.unix(), DEFAULT_TIME_RANGE.end.unix());

    this.dataView = new DataView(this.horizontalViewport);

    this.timelineDataRange = new Viewport(0, 0);
    this.timelinePeriodsTogglePresenter = new TimelinePeriodsTogglePresenter(
      this.horizontalViewport,
      this.timelineDataRange
    );

    this.timelineDataModel = new TimelineDataModel(this.timelineDataRange);

    this.timelineViewport = new Viewport(0, 0);
    this.timeUnit = TimeUnit.month;
    this.horizontalViewportController = new TimelineController(this.timeUnit);
    this.timelinePresenter = new TimelinePresenter(
      this.horizontalViewport,
      this.timelineViewport,
      this.timelineDataRange,
      this.timelineDataModel,
      this.timeUnit,
      this.horizontalViewportController
    );

    this.headerModel = new HeaderModel(this.horizontalViewport);
    this.headerPresenter = new HeaderPresenter(
      this.horizontalViewport,
      this.headerModel,
      this.horizontalViewportController
    );

    this.indicatorsSettings = new IndicatorsTableSettingsStore(
      this.horizontalViewport,
      this.rootStore.editing,
      this.rootStore.views,
      this.rootStore.directories,
      this.rootStore.notifications
    );

    this.wellsGrouping = new ChartGrouping(DEFAULT_WELLS_CHART_GROUPING);

    makeObservable(this);
  }

  @flow.bound
  private async *loadDataBoundaries() {
    const { start, end } = await this.timelineApi.getDataBoundaries();
    yield;

    this.timelineDataRange.setRange(start, end);
  }

  @flow.bound
  private async *loadRigsChartViewSettings(): Promise<void> {
    try {
      this.isChartLoading = true;

      const [view, viewSettings] = await Promise.all([
        this.rootStore.views.rigsChartView.loadView(),
        this.rigsViewSettingsManager.getOrCreate(),
      ]);
      yield;

      const grouping = view.carpet.defaultGrouping;

      assert(isChartGroupType(grouping), `Invalid default grouping value: ${grouping}.`);

      this.rigsGrouping = new ChartGrouping(grouping);

      this.rigsViewSettings = new RigsViewSettingsStore(
        this.rootStore,
        new RigsChartView(view, viewSettings),
        this.rigsViewSettingsManager
      );
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToLoadChartSettings');
    } finally {
      this.isChartLoading = false;
    }
  }

  @flow.bound
  private async *loadWellsChartViewSettings(): Promise<void> {
    try {
      this.isChartLoading = true;

      const [viewSettings, view] = await Promise.all([
        this.wellsViewSettingsManager.getOrCreate(),
        this.rootStore.views.wellsChartView.loadView(),
      ]);
      yield;

      this.wellsViewSettings = new WellsViewSettingsStore(
        this.rootStore,
        new WellsChartView(view, viewSettings),
        this.wellsViewSettingsManager
      );
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToLoadChartSettings');
    } finally {
      this.isChartLoading = false;
    }
  }

  @flow.bound
  private async *createRigsChart() {
    if (!this.rigsViewSettings) {
      await this.loadRigsChartViewSettings();
      yield;
    }

    if (this.rigsViewSettings && this.rigsGrouping) {
      this.rigsChartStore = new RigsChartStore(
        this.horizontalViewport,
        this.dataView,
        this.rootStore,
        this.rigsViewSettings,
        this.horizontalViewportController,
        this.rigsGrouping
      );
    }
  }

  @flow.bound
  private async *createWellsChart() {
    if (!this.wellsViewSettings) {
      await this.loadWellsChartViewSettings();
      yield;
    }

    if (this.wellsViewSettings) {
      this.wellsChartStore = new WellsChartStore(
        this.horizontalViewport,
        this.dataView,
        this.rootStore,
        this.wellsViewSettings,
        this.horizontalViewportController,
        this.wellsGrouping,
        {
          wellIdFieldName: this.wellsViewSettings.view.view.carpet.wells.idAttrName,
        }
      );
    }
  }

  @flow.bound
  private async *createComparingRigsChart(firstPlanVersionId: number, secondPlanVersionId: number) {
    if (!this.rigsViewSettings) {
      await this.loadRigsChartViewSettings();
      yield;
    }

    if (this.rigsViewSettings && this.rigsGrouping) {
      this.comparingRigsChartStore = new ComparingRigsChartStore(
        firstPlanVersionId,
        secondPlanVersionId,
        this.horizontalViewport,
        this.dataView,
        this.rootStore,
        this.rigsViewSettings,
        this.horizontalViewportController,
        this.rigsGrouping
      );
    }
  }

  @flow.bound
  private async *createComparingWellsChart(firstPlanVersionId: number, secondPlanVersionId: number) {
    if (!this.wellsViewSettings) {
      await this.loadWellsChartViewSettings();
      yield;
    }

    if (this.wellsViewSettings) {
      this.comparingWellsChartStore = new ComparingWellsChartStore(
        firstPlanVersionId,
        secondPlanVersionId,
        this.horizontalViewport,
        this.dataView,
        this.rootStore,
        this.wellsViewSettings,
        this.horizontalViewportController,
        this.wellsGrouping,
        {
          wellIdFieldName: this.wellsViewSettings.view.view.carpet.wells.idAttrName,
        }
      );
    }
  }

  @flow.bound
  async *reloadIndicatorsData() {
    if (this.rigsChartStore && this.header.displayChartType === PlanType.rigs) {
      await this.rigsChartStore.indicators.reloadIndiatorsData();
      yield;

      return;
    }

    if (this.wellsChartStore && this.header.displayChartType === PlanType.wells) {
      await this.wellsChartStore.indicators.reloadIndiatorsData();
      yield;

      return;
    }
  }

  @flow.bound
  async *reloadChartData(planVersionId: number) {
    if (this.rigsChartStore && this.header.displayChartType === PlanType.rigs) {
      await this.rigsChartStore.loadChartData(planVersionId);
      yield;

      return;
    }

    if (this.wellsChartStore && this.header.displayChartType === PlanType.wells) {
      await this.wellsChartStore.loadChartData(planVersionId);
      yield;

      return;
    }
  }

  @flow.bound
  async *reloadChartDataAfterSavingForm() {
    if (hasValue(this.rootStore.drafts.draftVersionId)) {
      await Promise.all([this.reloadChartData(this.rootStore.drafts.draftVersionId), this.reloadIndicatorsData()]);
      yield;
    }
  }

  @flow.bound
  async *checkConflicts(): Promise<boolean> {
    const res = await this.rigsChartStore?.checkConflicts();
    yield;

    return res;
  }

  @action.bound
  async onFormClose() {
    await this.reloadChartDataAfterSavingForm();
    this.wellFormManager.setIsFormOpen(false);
    this.wellFormManager.resetFormManager();
  }

  @action.bound
  init(): VoidFunction {
    this.loadDataBoundaries();
    this.indicatorsSettings.init();

    const disposeHorizontalViewport = this.horizontalViewport.init();

    const disposeHeaderPresenter = this.headerPresenter.init();

    const disoseComparingVersionsChanging = reaction(
      () => ({
        displayChartType: this.header.displayChartType,
        isComparing: this.rootStore.comparison.isComparing,
        firstPlanVersion: this.rootStore.comparison.firstTargetPlan,
        secondPlanVersion: this.rootStore.comparison.secondTargetPlan,
      }),
      ({ isComparing, displayChartType, firstPlanVersion, secondPlanVersion }) => {
        if (displayChartType === PlanType.rigs) {
          if (isComparing && firstPlanVersion && secondPlanVersion) {
            this.createComparingRigsChart(firstPlanVersion.id, secondPlanVersion.id);
          }
        } else if (displayChartType === PlanType.wells) {
          if (isComparing && firstPlanVersion && secondPlanVersion) {
            this.createComparingWellsChart(firstPlanVersion.id, secondPlanVersion.id);
          }
        }
      },
      { fireImmediately: true }
    );

    const disposeComparingModeChanging = reaction(
      () => ({
        displayChartType: this.header.displayChartType,
        isComparing: this.rootStore.comparison.isComparing,
      }),
      ({ isComparing, displayChartType }) => {
        if (displayChartType === PlanType.rigs) {
          if (!isComparing) {
            this.createRigsChart();
          }
        } else if (displayChartType === PlanType.wells) {
          if (!isComparing) {
            this.createWellsChart();
          }
        }
      },
      { fireImmediately: true }
    );

    return () => {
      disposeHeaderPresenter();
      disposeHorizontalViewport();
      disoseComparingVersionsChanging();
      disposeComparingModeChanging();
    };
  }
}
