export enum ChartGroupType {
  mineralsUser = 'MINERALS_USER',
  drillingContractor = 'DRILLING_CONTRACTOR',
  rigModel = 'RIG_MODEL',
  block = 'BLOCK',
  field = 'FIELD',
  pad = 'PAD',
  wellPurpose = 'WELL_PURPOSE',
  wellType = 'WELL_TYPE',
}

export const DEFAULT_WELLS_CHART_GROUPING = ChartGroupType.mineralsUser;

export const isChartGroupType = (value: unknown): value is ChartGroupType =>
  typeof value === 'string' && Object.values<string>(ChartGroupType).includes(value);
