import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectPageModal } from 'src/features/modals/select-page-modal';
import { UploadModal } from 'src/features/modals/upload-modal';
import { ReactComponent as DownloadIcon } from 'src/shared/assets/icons/download-arrow.svg';
import { useStore } from 'src/store/use-store';

import { ButtonWithTooltip } from '../../../../../shared/components/button-with-tooltip';

import { UploadButtonStore } from './upload-button.store';

import styles from './compare-and-import-export.module.scss';

export const UploadButton = observer(function UploadButton() {
  const { t } = useTranslation('drillingCarpet');

  const store = useStore();
  const { comparison, editing } = store;

  const [uploadButtonStore] = useState(() => new UploadButtonStore(store));
  const {
    openUploadModal,
    isSelectPageModalOpened,
    isUploadModalOpened,
    closeUploadModal,
    closeSelectPageModal,
    uploadFile,
    isLoading,
    sheets,
    selectSheet,
  } = uploadButtonStore;

  return (
    <>
      <ButtonWithTooltip
        onClick={openUploadModal}
        icon={<DownloadIcon className={styles.icon} />}
        isDisabled={editing.isEditing || comparison.isComparing}
        className={clsx(styles.iconButton, comparison.isComparing && styles.hidden)}
        tooltipText={t('common:import')}
      />

      <UploadModal
        isUploadModalOpened={isUploadModalOpened}
        onClose={closeUploadModal}
        onUpload={uploadFile}
        isLoading={isLoading}
      />
      <SelectPageModal
        sheets={sheets ?? []}
        onSelectSheet={selectSheet}
        onClose={closeSelectPageModal}
        isOpen={isSelectPageModalOpened}
      />
    </>
  );
});
