import { hasValue } from 'src/shared/utils/common';

import {
  InsertionPoint,
  InteractiveInsertionPoint,
  PadRigOperation,
  TemporaryChartRig,
  WellRigOperation,
} from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { RigOperationsDnd } from '../../../presets/rig-operations-dnd/rig-operations-dnd-module';
import { DateHelper } from '../../../shared/date-helper';
import { DraggableGhost } from '../../editing/shared/entities/draggable-ghost';
import { InsertionPointNeighbor } from '../../editing/shared/entities/insertion-point-neighbor';
import { Dnd } from '../../editing/types';

export namespace InsertionPointsCalculator {
  type GroupedItems = {
    insertionPoints: InsertionPoint<WellRigOperation>[];
    rest: RigsChartStore.ViewItem[];
  };

  function createInsertionPointsForWell(
    item: WellRigOperation,
    active: Dnd.Draggable<RigOperationsDnd.DraggableItem>,
    now: number
  ): GroupedItems {
    const editingItems: RigOperationsDnd.ViewItem[] = [];
    const insertionPoints: InsertionPoint<WellRigOperation>[] = [];

    const currentWell = item;
    const nextWell = currentWell.neighborScheme?.right.neighbor;

    const isActiveDraggable = currentWell === active;

    if (currentWell && !isActiveDraggable) {
      const isNextActive = nextWell === active || nextWell?.parentPad === active;

      if (nextWell && !isNextActive) {
        const insertion = InsertionPointsCalculator.createInsertionPoint(currentWell, nextWell, now);

        if (insertion) {
          insertionPoints.push(insertion);
        }
      }

      editingItems.push(new InsertionPointNeighbor(currentWell));
    }

    return { insertionPoints, rest: editingItems };
  }

  function createInsertionPointsForPad(
    item: PadRigOperation,
    active: Dnd.Draggable<RigOperationsDnd.DraggableItem>,
    now: number
  ): GroupedItems {
    const editingItems: RigOperationsDnd.ViewItem[] = [];
    const insertionPoints: InsertionPoint<WellRigOperation>[] = [];

    const currentPad = item;
    const previousWell = currentPad.neighborScheme?.left.neighbor;
    const nextWell = currentPad.neighborScheme?.right.neighbor;

    const isActiveDraggable = currentPad === active;

    if (currentPad && !isActiveDraggable) {
      const isNextActive = nextWell === active || nextWell?.parentPad === active;

      if (!isNextActive && currentPad.lastWell !== active) {
        const insertion = InsertionPointsCalculator.createInsertionPoint(currentPad, nextWell, now);

        if (insertion) {
          insertionPoints.push(insertion);
        }
      }

      if (!previousWell && currentPad.firstWell !== active) {
        const insertion = InsertionPointsCalculator.createInsertionPoint(undefined, currentPad, now);

        if (insertion) {
          insertionPoints.push(insertion);
        }
      }

      editingItems.push(new InsertionPointNeighbor(currentPad));
    }

    return { insertionPoints, rest: editingItems };
  }

  export function createInsertionPoint(
    first: WellRigOperation | PadRigOperation | undefined,
    second: WellRigOperation | PadRigOperation | undefined,
    now: number
  ): InsertionPoint<WellRigOperation> | null {
    function getLeftWell(): WellRigOperation | undefined {
      if (first instanceof WellRigOperation) {
        return first;
      }

      return first?.lastWell;
    }
    function getRightWell(): WellRigOperation | undefined {
      if (second instanceof WellRigOperation) {
        return second;
      }

      return second?.firstWell;
    }

    if (first && second) {
      const timeStart = first.x?.end;
      const timeEnd = second.x?.start;

      if (hasValue(timeStart) && hasValue(timeEnd)) {
        return new InsertionPoint(
          { start: timeStart, end: timeEnd },
          {
            start: Math.min(first.y.start, second.y.start),
            end: Math.min(first.y.end, second.y.end),
          },
          `insertion-${first.getKey()}-${second.getKey()}`,
          timeEnd > now,
          getLeftWell(),
          getRightWell()
        );
      }
    }

    if (first && !second) {
      const timeStart = first.x?.end;

      if (hasValue(timeStart)) {
        const timeEnd = timeStart + 1;

        return new InsertionPoint(
          { start: timeStart, end: timeEnd },
          {
            start: first.y.start,
            end: first.y.end,
          },
          `insertion-after-${first.getKey()}`,
          timeEnd > now,
          getLeftWell(),
          getRightWell()
        );
      }
    }

    if (!first && second) {
      const timeEnd = second.x?.start;

      if (hasValue(timeEnd)) {
        const timeStart = timeEnd - 1;

        return new InsertionPoint(
          { start: timeStart, end: timeEnd },
          {
            start: second.y.start,
            end: second.y.end,
          },
          `insertion-before-${second.getKey()}`,
          timeEnd > now,
          getLeftWell(),
          getRightWell()
        );
      }
    }

    return null;
  }

  export function getDataWithInsertionPoints(
    data: RigsChartStore.ViewItem[],
    activeGhost: Readonly<DraggableGhost<RigOperationsDnd.DraggableItem, RigOperationsDnd.DraggableShadow>>
  ): GroupedItems {
    const now = DateHelper.dateToUnix(new Date());
    const editingItems: RigsChartStore.ViewItem[] = [];
    const insertionPoints: InsertionPoint<WellRigOperation>[] = [];

    for (const item of data) {
      if (item instanceof WellRigOperation) {
        const editingItemsWithInsertionPoints = createInsertionPointsForWell(item, activeGhost.parent, now);

        insertionPoints.push(...editingItemsWithInsertionPoints.insertionPoints);
        editingItems.push(...editingItemsWithInsertionPoints.rest);
      } else if (item instanceof PadRigOperation) {
        const editingItemsWithInsertionPoints = createInsertionPointsForPad(item, activeGhost.parent, now);

        insertionPoints.push(...editingItemsWithInsertionPoints.insertionPoints);
        editingItems.push(...editingItemsWithInsertionPoints.rest);
      } else {
        editingItems.push(item);
      }
    }

    return { insertionPoints, rest: editingItems };
  }

  export function getInsertionPointForTemporaryRig(
    data: RigsChartStore.ViewItem[],
    activeGhost: Readonly<DraggableGhost<RigOperationsDnd.DraggableItem, RigOperationsDnd.DraggableShadow>>
  ): GroupedItems {
    const now = DateHelper.dateToUnix(new Date());
    const editingItems: RigsChartStore.ViewItem[] = [];
    const insertionPoints: InsertionPoint<WellRigOperation>[] = [];

    for (const item of data) {
      if (item !== activeGhost.parent) {
        editingItems.push(item);
      }

      if (item instanceof TemporaryChartRig && item.y) {
        insertionPoints.push(
          new InteractiveInsertionPoint<WellRigOperation>(
            null,
            item.y,
            item.getKey('insertion'),
            true,
            undefined,
            undefined,
            { minStartX: now }
          )
        );
      }
    }

    return { insertionPoints, rest: editingItems };
  }
}
