import { makeObservable, action, observable, computed, flow } from 'mobx';
import { ChangeEvent } from 'react';

import { deletePlanVersion } from 'src/api/import-file';
import { publishVersion } from 'src/api/version';
import { BaseApiError } from 'src/errors';
import { DraftsStore } from 'src/store/drafts/drafts-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { PlanVersionStore } from 'src/store/plan-version';

import { PlanVersion, TActualPlansGroups, TArchivedPlansGroup } from './types';

export class ComparisonStore {
  private readonly planVersionStore: PlanVersionStore;
  private readonly draftStore: DraftsStore;
  private readonly notifications: NotificationsStore;

  @observable firstTargetPlan: PlanVersion | null;
  @observable secondTargetPlan: PlanVersion | null;
  @observable searchingValue: string;
  @observable isComparing: boolean;
  @observable isImportOccurred: boolean;
  @observable isCompareSidebarOpened: boolean;
  @observable isActual: boolean;
  @observable isLoading = false;

  constructor(planVersionStore: PlanVersionStore, draftsStore: DraftsStore, notifications: NotificationsStore) {
    this.planVersionStore = planVersionStore;
    this.searchingValue = '';
    this.firstTargetPlan = null;
    this.secondTargetPlan = null;
    this.isComparing = false;
    this.isImportOccurred = false;
    this.isCompareSidebarOpened = false;
    this.isActual = true;
    this.draftStore = draftsStore;
    this.notifications = notifications;

    makeObservable(this);
  }

  @action.bound
  setFirstPlan(plan: PlanVersion | null) {
    this.firstTargetPlan = plan;
  }

  @action.bound
  setSecondPlan(plan: PlanVersion | null) {
    this.secondTargetPlan = plan;
  }

  @action.bound
  setPlanForComparison(planVersion: PlanVersion) {
    if (this.firstTargetPlan?.id === planVersion.id) {
      this.setFirstPlan(null);
      return;
    }
    if (this.secondTargetPlan?.id === planVersion.id) {
      this.setSecondPlan(null);
      return;
    }
    if (!this.firstTargetPlan) {
      this.setFirstPlan(planVersion);
      return;
    }
    if (!this.secondTargetPlan) {
      this.setSecondPlan(planVersion);
      return;
    }
  }

  @action.bound
  toggleActual() {
    this.isActual = !this.isActual;
  }

  @action.bound
  openSidebar() {
    this.isCompareSidebarOpened = true;
  }

  @action.bound
  closeSidebar() {
    this.isCompareSidebarOpened = false;
    if (!this.isComparing) {
      this.firstTargetPlan = null;
      this.secondTargetPlan = null;
    }
  }

  @action.bound
  submitComparing() {
    this.isCompareSidebarOpened = false;
    this.isComparing = true;
  }

  @action.bound
  submitComparingWithImport(firstPlan: PlanVersion, secondPlan: PlanVersion) {
    this.submitComparing();
    this.setFirstPlan(firstPlan);
    this.setSecondPlan(secondPlan);
    this.isImportOccurred = true;
  }

  @action.bound
  completeComparing() {
    this.isComparing = false;
    this.isImportOccurred = false;
    this.firstTargetPlan = null;
    this.secondTargetPlan = null;
  }

  @computed
  get sidebarData() {
    const lowerSearchingValue = this.searchingValue.toLowerCase();

    if (this.isActual) {
      const filteredActualData: TActualPlansGroups[] = [];

      for (const group of this.planVersionStore.actualData) {
        const filteredPlans = group.plans.filter((plan) => plan.data.name.toLowerCase().includes(lowerSearchingValue));

        if (filteredPlans.length) {
          filteredActualData.push({
            ...group,
            plans: filteredPlans,
          });
        }
      }

      return filteredActualData;
    } else {
      const filteredArchivedData: TArchivedPlansGroup[] = [];

      for (const group of this.planVersionStore.archivedData) {
        const filteredMonths: TArchivedPlansGroup['months'] = [];

        group.months.forEach((month) => {
          const filteredPlans = month.plans.filter((plan) =>
            plan.data.name.toLowerCase().includes(lowerSearchingValue)
          );

          if (filteredPlans.length) {
            filteredMonths.push({
              ...month,
              plans: filteredPlans,
            });
          }
        });

        if (filteredMonths.length) {
          filteredArchivedData.push({
            ...group,
            months: filteredMonths,
          });
        }
      }

      return filteredArchivedData;
    }
  }

  @action.bound
  searchPlan(event: ChangeEvent<HTMLInputElement>) {
    this.searchingValue = event.target.value.toLowerCase();
  }

  @flow.bound
  async *cancelImport() {
    if (!this.isImportOccurred || !this.secondTargetPlan) {
      return;
    }

    try {
      await deletePlanVersion(this.secondTargetPlan?.id);
      await this.draftStore.fetchDraftsList();
      yield;

      this.completeComparing();
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToDeletePlanVersion');
    }
  }

  @flow.bound
  async *publishImportVersion() {
    if (!this.isImportOccurred || !this.secondTargetPlan) {
      return;
    }

    this.isLoading = true;
    try {
      await publishVersion(this.secondTargetPlan.id);
      yield;

      this.completeComparing();

      await this.planVersionStore.reloadVersions();
      await this.draftStore.fetchDraftsList();
      yield;
    } catch (error) {
      yield;

      console.error(error);
      if (error instanceof BaseApiError && error.responseMessage) {
        this.notifications.showErrorMessage(error.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToPublishVersion');
    } finally {
      this.isLoading = false;
    }
  }
}
