import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { TRigOperationSection } from '../../graph-info-sidebar';

export class Section {
  private readonly item: Record<string, unknown>;
  private readonly view: TRigOperationSection;
  private readonly directories: Directories;
  private readonly notifications: NotificationsStore;
  readonly id: number;

  constructor(
    data: Record<string, unknown>,
    view: TRigOperationSection,
    directories: Directories,
    notifications: NotificationsStore,
    id: number
  ) {
    this.item = data;
    this.view = view;
    this.directories = directories;
    this.notifications = notifications;
    this.id = id;
  }

  get title(): string {
    const { refObjectType, refObjectAttr } = this.view;

    if (refObjectType && refObjectAttr) {
      const refObject = this.directories.getObject(refObjectType);
      const labelObject = refObject?.find(({ id }) => id.toString() === this.id.toString());
      const label = labelObject?.data[refObjectAttr];

      return label ? label.toString() : '';
    }

    return this.id.toString() || '';
  }

  get start(): number | null {
    const startDate = this.item['startDate'];

    if (typeof startDate === 'number' || (typeof startDate === 'string' && !Number.isNaN(Number(startDate)))) {
      return Number(startDate);
    }

    return null;
  }

  get end(): number | null {
    const endDate = this.item['endDate'];

    if (typeof endDate === 'number' || (typeof endDate === 'string' && !Number.isNaN(Number(endDate)))) {
      return Number(endDate);
    }

    return null;
  }

  get duration(): number | null {
    const duration = this.item['duration'];

    if (typeof duration === 'number' || (typeof duration === 'string' && !Number.isNaN(Number(duration)))) {
      return Number(duration);
    }

    return null;
  }
}
