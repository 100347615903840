import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DraftsListItem } from 'src/features/plan-version-sidebar/drafts-list-item';
import { VersionsListItem } from 'src/features/plan-version-sidebar/versions-list-item';
import Collapse, { Panel } from 'src/shared/components/collapse/collapse';
import { PlanVersion, TActualPlansGroups } from 'src/store/comparison/types';

import styles from './versions-group.module.scss';

interface Props {
  versionsGroup: TActualPlansGroups;
  onVersionChange(planVersion: PlanVersion): void;
  onDeleteDraftVersion(draftVersion: PlanVersion): void;
}

export const VersionsGroup = observer(function VersionsGroup({
  versionsGroup,
  onVersionChange,
  onDeleteDraftVersion,
}: Props) {
  const { t } = useTranslation();

  if (!versionsGroup.plans.length) {
    return null;
  }

  return (
    <Collapse key={versionsGroup.name} defaultActiveKey={versionsGroup.name}>
      <Panel key={versionsGroup.name} header={t(`drawers:planVersion.${versionsGroup.name}`)} className={styles.text}>
        <ul>
          {versionsGroup.plans.map((version, index) => {
            return versionsGroup.name === 'drafts' ? (
              <DraftsListItem
                key={index}
                version={version}
                onVersionChange={onVersionChange}
                onDeleteDraftVersion={onDeleteDraftVersion}
              />
            ) : (
              <VersionsListItem key={index} version={version} onVersionChange={onVersionChange} />
            );
          })}
        </ul>
      </Panel>
    </Collapse>
  );
});
