import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { WellTypes } from 'src/types/well-types';

import { IWellsChartDataApi } from '../../features/drilling-chart/features/wells-chart/data/wells-chart-data.types';
import { ChartWell, WellRigOperation } from '../../features/drilling-chart/presets/drilling-wells-chart/entities';
import { ChartGroupType } from '../../features/drilling-chart/shared/chart-group-type';
import { ChartFiltersForm } from '../../features/drilling-chart/shared/filters-form.store';

import { WellsChartDataAdapter } from './wells-chart-data-adapter';

export class WellsChartDataApi implements IWellsChartDataApi {
  private readonly adapter = new WellsChartDataAdapter();

  async getWellsStages(
    planVersionId: number,
    wellIdList: number[],
    groupIdList: number[],
    groupType: ChartGroupType,
    startDate: number,
    endDate: number,
    wells: Map<number, Map<number, ChartWell>>,
    filter: ChartFiltersForm.FilterValues = {}
  ): Promise<Map<number, Map<number, WellRigOperation[]>>> {
    try {
      const { data } = await agent.post<WellTypes.RawWellsGroupWithStages[]>('graph/well/rigOperation/list', filter, {
        params: {
          planVersionId,
          wellIdList: wellIdList.join(),
          groupIdList: groupIdList.join(),
          groupType,
          startDate,
          endDate,
        },
      });

      return this.adapter.initWellsStages(data, wellIdList, groupIdList, wells, planVersionId);
    } catch (e) {
      throwApiError(e);
    }
  }

  async deleteWell(planVersionId: number, wellId: number): Promise<void> {
    try {
      await agent.post('rig/removeRig', null, {
        params: { planVersionId, objectType: 'WELL', objectIdList: wellId.toString() },
      });
    } catch (e) {
      throwApiError(e);
    }
  }
}
