import { i18n as Ii18 } from 'i18next';
import { flow, makeObservable, observable, reaction } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { RigConflictsSidebarStore } from 'src/features/drilling-rig-conflicts-sidebar/store';

import { AppSettingsStore } from './app-settings/app-settings-store';
import { AuthStore } from './auth/auth-store';
import { ComparisonStore } from './comparison/comparison-store';
import { Directories } from './directories/directories.store';
import { DraftsStore } from './drafts/drafts-store';
import { EditingStore } from './editing/editing-store';
import { GlobalVariables } from './global-variables/global-variables';
import { I18NextStore } from './i18next/i18next-store';
import { LanguageStore } from './language/language-store';
import { NotificationsStore } from './notifications-store/notifications-store';
import { PlanVersionStore } from './plan-version';
import { RouterStore } from './router/router-store';
import { SidebarStore } from './sidebar/sidebar-store';
import { ThemeStore } from './theme/theme-store';
import { ViewsStore } from './views';

export class RootStore {
  readonly auth: AuthStore;
  readonly directories;
  readonly globalVariables: GlobalVariables;
  readonly router: RouterStore;
  readonly sidebar: SidebarStore;
  readonly i18: I18NextStore;
  readonly language: LanguageStore;
  readonly theme: ThemeStore;
  readonly notifications: NotificationsStore;
  readonly comparison: ComparisonStore;
  readonly drafts: DraftsStore;
  readonly editing: EditingStore;
  readonly planVersion: PlanVersionStore;
  readonly views: ViewsStore;
  readonly appSettings: AppSettingsStore;
  // Used to resolve conflicts.
  readonly rigConflictSidebar: RigConflictsSidebarStore;

  @observable isInitialized = false;
  @observable isLoading = false;

  constructor({ navigate, i18 }: RootStore.InitArg) {
    this.globalVariables = new GlobalVariables();
    this.i18 = new I18NextStore(i18);
    this.appSettings = new AppSettingsStore();
    this.notifications = new NotificationsStore(this.i18);
    this.auth = new AuthStore(this.globalVariables, this.notifications);
    this.i18 = new I18NextStore(i18);
    this.router = new RouterStore(navigate);
    this.sidebar = new SidebarStore();
    this.language = new LanguageStore();
    this.theme = new ThemeStore();
    this.planVersion = new PlanVersionStore(this.notifications);
    this.directories = new Directories(this.i18, this.notifications);
    this.views = new ViewsStore();
    this.rigConflictSidebar = new RigConflictsSidebarStore(this.directories, this.views, this.notifications);
    this.drafts = new DraftsStore(this);
    this.comparison = new ComparisonStore(this.planVersion, this.drafts, this.notifications);
    this.editing = new EditingStore(this.drafts, this.planVersion, this.notifications);

    makeObservable(this);
  }

  @flow.bound
  private async *initApp() {
    try {
      await this.auth.initUserService();
      await this.auth.loadUserData();
      yield;

      if (!this.auth.isAuthenticated) {
        return;
      }

      try {
        this.appSettings.init();
      } catch (e) {
        yield;

        console.error(e);
        this.notifications.showErrorMessageT('errors:failedToLoadAppSettings');
      }

      await this.directories.loadLabelsAndInterpretations();
      yield;

      this.planVersion.init();
      this.drafts.init();
      this.language.init();
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:appInitializationError');
    } finally {
      this.isInitialized = true;
    }
  }

  init() {
    this.initApp();

    const disposeAppLanguage = reaction(() => this.language.language, this.changeLanguage);

    return () => {
      disposeAppLanguage();
    };

    // функционал временно отключен
    // const editingStoreDisposer = this.editing.init();

    // return () => {
    //   editingStoreDisposer();
    // };
  }

  @flow.bound
  async *changeLanguage() {
    this.isLoading = true;

    try {
      await this.directories.loadLabelsAndInterpretations();
      yield;
    } finally {
      this.isLoading = false;
    }
  }
}

export namespace RootStore {
  export type InitArg = {
    navigate: NavigateFunction;
    i18: Ii18;
  };
}
