import { observer } from 'mobx-react-lite';

import { PlanVersion } from 'src/store/comparison/types';

import styles from './versions-list-item.module.scss';

interface Props {
  version: PlanVersion;
  onVersionChange(planVersion: PlanVersion): void;
}

export const VersionsListItem = observer(function VersionsListItem({ version, onVersionChange }: Props) {
  return (
    <li className={styles.listItem} onClick={() => onVersionChange(version)}>
      <p className={styles.listItemText}>{version.data.name}</p>
    </li>
  );
});
