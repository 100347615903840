import { action, comparer, computed, flow, makeObservable, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';

import { RigsChartDataTypes } from 'src/api/chart/rigs-chart-data-api';
import { ConflictApi } from 'src/api/draft/conflict-api';
import { BaseApiError } from 'src/errors';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { RootStore } from 'src/store';
import { EditingStore } from 'src/store/editing/editing-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { AddRigSidebar } from '../../../../../api/chart/add-rig-sidebar-api';
import { IChartDataModel, Range } from '../../../layers/model';
import {
  ChartRig,
  LoadingRigOperations,
  PadRigOperation,
  RigsGroup,
  TemporaryChartRig,
} from '../../../presets/drilling-rigs-chart/entities';
import { ChartGroupType } from '../../../shared/chart-group-type';
import { DataView } from '../../../shared/data-view/data-view';
import { ChartFiltersForm } from '../../../shared/filters-form.store';
import { RigsDataPositionsCalculator } from '../../../shared/rigs-data-positions-calculator';
import { IChartElement, RawWell } from '../../../types';

import { IRigsChartDataApi, IRigsChartDataStorage } from './rigs-chart-data.types';

export class RigsChartDataModel implements IChartDataModel<RigsChartDataModel.ViewItem[] | null> {
  private readonly api: IRigsChartDataApi;
  private readonly conflictApi: ConflictApi;
  private readonly dataView: DataView;
  private readonly storage: IRigsChartDataStorage;
  private readonly editing: EditingStore;

  private readonly notifications: NotificationsStore;

  @observable private filter?: ChartFiltersForm.FilterValues;
  @observable private grouping?: ChartGroupType;

  @observable isLoading = false;

  constructor(
    initialVerticalViewRange: Range<number>,
    initialHorizontalViewRange: Range<number>,
    dataView: DataView,
    api: IRigsChartDataApi,
    conflictApi: ConflictApi,
    storage: IRigsChartDataStorage,
    rootStore: RootStore
  ) {
    this.editing = rootStore.editing;
    this.notifications = rootStore.notifications;

    this.dataView = dataView;
    this.api = api;
    this.conflictApi = conflictApi;

    this.storage = storage;
    this.storage.setVerticalViewRange(initialVerticalViewRange.start, initialVerticalViewRange.end);
    this.storage.setHorizontalViewRange(initialHorizontalViewRange.start, initialHorizontalViewRange.end);

    makeObservable(this);
  }

  @flow.bound
  async *removeRigOperation(
    tripleId: number,
    targetRigId: number,
    forceDelete?: boolean
  ): Promise<RigsChartDataTypes.RemoveResponse> {
    const planVersionId = this.editing.actualPlanVersionId;

    if (!hasValue(planVersionId)) {
      return;
    }

    this.isLoading = true;

    try {
      const res = await this.api.removeRigOperation(planVersionId, tripleId, forceDelete);
      yield;

      if (!res.needConfirmation) {
        this.storage.clearRigsData([targetRigId]);
      }

      return res;
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('errors:removeRigOperation');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *removeRigOperationsList(
    tripleId: number,
    targetRigId: number,
    forceDelete?: boolean
  ): Promise<RigsChartDataTypes.RemoveResponse> {
    const planVersionId = this.editing.actualPlanVersionId;

    if (!hasValue(planVersionId)) {
      return;
    }

    this.isLoading = true;

    try {
      const res = await this.api.removeRigOperationList(planVersionId, tripleId, forceDelete);
      yield;

      if (!res.needConfirmation) {
        this.storage.clearRigsData([targetRigId]);
      }

      return res;
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('errors:removeRigOperationsList');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  private async *downloadWells(
    planVersionId: number,
    rigsIds: number[],
    horizontalRange: Range<number>,
    groupType: ChartGroupType,
    groupIds: number[],
    groupsWithRigs: RigsGroup[]
  ) {
    const wells = await this.api.getWells(
      planVersionId,
      horizontalRange.start,
      horizontalRange.end,
      rigsIds,
      groupType,
      groupIds,
      groupsWithRigs,
      this.filter
    );
    yield;

    if (wells) {
      this.storage.setRigOperations(wells, horizontalRange, this.dataView.type);
    }
  }

  @computed
  get verticalDataRange(): Range<number> {
    return this.storage.allDataVerticalRange || { start: 0, end: 0 };
  }

  @action.bound
  init(): VoidFunction {
    const disposeStorage = this.storage.init?.();

    const disposePositionsCalculating = reaction(
      () => ({
        dataViewType: this.dataView.type,
      }),
      () => {
        this.storage.normalizeData();
      },
      { equals: comparer.shallow, fireImmediately: true }
    );

    const disposeMissingDataProcess = reaction(
      () => ({ missingData: this.storage.missingDataBounds }),
      ({ missingData }) => {
        const planVersionId = this.editing.actualPlanVersionId;

        assert(hasValue(planVersionId), 'Invalid plan version ID.');

        const groupsWithRigs = this.storage.rigsGroups;

        if (missingData?.length && this.grouping && groupsWithRigs) {
          for (const missingDataRange of missingData) {
            this.downloadWells(
              planVersionId,
              [...missingDataRange.rigsIds],
              missingDataRange.horizontalRange,
              this.grouping,
              [...missingDataRange.groupIds],
              groupsWithRigs
            );
          }
        }
      }
    );

    return () => {
      disposeStorage?.();
      disposePositionsCalculating();
      disposeMissingDataProcess();
    };
  }

  @computed({ equals: comparer.shallow })
  get data(): RigsChartDataModel.ViewItem[] | null {
    const data = this.storage.data;

    return data?.items?.length ? data.items : null;
  }

  get rigGroups(): RigsGroup[] | undefined {
    return this.storage.rigsGroups;
  }

  getRigsInView = computedFn(
    (): number[] | null => {
      const data = this.storage.data;

      return data?.rigIds ? [...data?.rigIds] : null;
    },
    { equals: comparer.shallow }
  );

  @action.bound
  setVerticalRange(start: number, end: number) {
    this.storage.setVerticalViewRange(start, end);
  }

  @action.bound
  setHorizontalRange(start: number, end: number) {
    this.storage.setHorizontalViewRange(start, end);
  }

  @action.bound
  setRigs(rigsGroups: RigsGroup[]) {
    this.storage.setRigs(rigsGroups);
  }

  @action.bound
  setShownWellAttributesNumber(attributesNumber: number): void {
    this.storage.setShownWellAttributesNumber(attributesNumber, this.dataView.type);
  }

  @action.bound
  recalculatePositions() {
    this.storage.normalizeData();
  }

  /**
   * Set settings that do not depend on the user and can be changed when the mode is changed.
   * For example, elements sizes. */
  @action.bound
  setChartSettings(settings: RigsChartDataModel.ChartSettings): void {
    this.storage.setPositionsCalculator(new RigsDataPositionsCalculator(settings.calculation));
  }

  @action.bound
  setFiltersAndGrouping(filter: ChartFiltersForm.FilterValues, grouping: ChartGroupType): void {
    this.grouping = grouping;
    this.filter = filter;
  }

  @action.bound
  addTemporaryRig(rig: AddRigSidebar.RigData): TemporaryChartRig {
    return this.storage.addTemporaryRig(rig);
  }

  @action.bound
  removeTemporaryRig(): void {
    this.storage.removeTemporaryRig();
  }

  @flow.bound
  async *checkConflicts(): Promise<RawWell | undefined> {
    const planVersionId = this.editing.actualPlanVersionId;

    if (!hasValue(planVersionId)) {
      return;
    }

    this.isLoading = true;

    try {
      const res = await this.conflictApi.checkConflicts(planVersionId);
      yield;

      return res;
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToCheckConflicts');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *changeRigOperationsOrder(changeInfo: RigsChartDataModel.OrderChangeInfo): Promise<RawWell | undefined> {
    const planVersionId = this.editing.actualPlanVersionId;

    if (!hasValue(planVersionId)) {
      return;
    }

    this.isLoading = true;

    try {
      const { insertion, targetRigId, initialRigId, insertAfter, insertOnPlace, startDate } = changeInfo;

      const res = await this.api.changeRigOperationsOrder(
        planVersionId,
        targetRigId,
        insertion,
        insertAfter,
        insertOnPlace,
        startDate
      );

      yield;

      initialRigId
        ? this.storage.clearRigsData([targetRigId, initialRigId])
        : this.storage.clearRigsData([targetRigId]);

      if (isObjectWithKeys(res)) {
        this.isLoading = false;

        return res;
      }
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:changeRigOperationsOrder');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *changePadsOrder(changeInfo: RigsChartDataModel.OrderChangeInfo): Promise<RawWell | undefined> {
    const planVersionId = this.editing.actualPlanVersionId;

    if (!hasValue(planVersionId)) {
      return;
    }

    this.isLoading = true;

    try {
      const { insertion, targetRigId, initialRigId, insertAfter, insertOnPlace, startDate } = changeInfo;

      const res = await this.api.changePadsOrder(
        planVersionId,
        targetRigId,
        insertion,
        insertAfter,
        insertOnPlace,
        startDate
      );
      yield;

      initialRigId
        ? this.storage.clearRigsData([targetRigId, initialRigId])
        : this.storage.clearRigsData([targetRigId]);

      if (isObjectWithKeys(res)) {
        this.isLoading = false;

        return res;
      }
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:changePadsOrder');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *changeRigOperationsListOrder(changeInfo: RigsChartDataModel.OrderChangeInfo): Promise<RawWell | undefined> {
    const planVersionId = this.editing.actualPlanVersionId;

    if (!hasValue(planVersionId)) {
      return;
    }

    this.isLoading = true;

    try {
      const { insertion, targetRigId, initialRigId, insertAfter, insertOnPlace, startDate } = changeInfo;

      const res = await this.api.changeRigOperationsListOrder(
        planVersionId,
        targetRigId,
        insertion,
        insertAfter,
        insertOnPlace,
        startDate
      );
      yield;

      initialRigId
        ? this.storage.clearRigsData([targetRigId, initialRigId])
        : this.storage.clearRigsData([targetRigId]);

      if (isObjectWithKeys(res)) {
        this.isLoading = false;
        return res;
      }
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:changeRigOperationsListOrder');
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  changeRigsOrder(current: ChartRig, placeOn: ChartRig, placement: 'before' | 'after'): RigsGroup[] | undefined {
    if (current === placeOn) {
      return;
    }

    if (!current.parentGroup) {
      throw new Error('Rig to be sorted must have a parent group.');
    }

    const isPositionNotChanged =
      (placement === 'before' &&
        current.parentGroup.items.indexOf(current) + 1 === current.parentGroup.items.indexOf(placeOn)) ||
      (placement === 'after' &&
        current.parentGroup.items.indexOf(current) - 1 === current.parentGroup.items.indexOf(placeOn));

    if (isPositionNotChanged) {
      return;
    }

    const filterCurrentSortable = (rig: ChartRig): boolean => rig !== current;

    const rigsOfCurrentGroup: ChartRig[] = current.parentGroup.items.filter(filterCurrentSortable);

    if (placement === 'before') {
      const insertPosition = rigsOfCurrentGroup.indexOf(placeOn);
      const left: ChartRig[] = rigsOfCurrentGroup.slice(0, insertPosition);
      const right: ChartRig[] = rigsOfCurrentGroup.slice(insertPosition);

      const updatedRigsList: ChartRig[] = [...left, current, ...right];

      current.parentGroup.setRigs(updatedRigsList);
    } else if (placement === 'after') {
      const insertPosition = rigsOfCurrentGroup.indexOf(placeOn);
      const left: ChartRig[] = rigsOfCurrentGroup.slice(0, insertPosition + 1);
      const right: ChartRig[] = rigsOfCurrentGroup.slice(insertPosition + 1);

      const updatedRigsList: ChartRig[] = [...left, current, ...right];

      current.parentGroup.setRigs(updatedRigsList);
    }

    this.recalculatePositions();

    return this.storage.rigsGroups;
  }

  @action.bound
  changeRigGroupsOrder(current: RigsGroup, placeOn: RigsGroup, placement: 'before' | 'after'): RigsGroup[] | undefined {
    if (current === placeOn) {
      return;
    }

    const { rigsGroups } = this.storage;

    const isPositionNotChanged =
      (placement === 'before' && rigsGroups && rigsGroups.indexOf(current) + 1 === rigsGroups.indexOf(placeOn)) ||
      (placement === 'after' && rigsGroups && rigsGroups.indexOf(current) - 1 === rigsGroups.indexOf(placeOn));

    if (isPositionNotChanged) {
      return;
    }

    const filterCurrentSortable = (group: RigsGroup): boolean => group !== current;

    const filteredRigGroups: RigsGroup[] = this.storage.rigsGroups?.filter(filterCurrentSortable) || [];

    if (!filteredRigGroups.length) {
      throw new Error('Groups are not found.');
    }

    if (placement === 'before') {
      const insertPosition = filteredRigGroups.indexOf(placeOn);
      const left: RigsGroup[] = filteredRigGroups.slice(0, insertPosition);
      const right: RigsGroup[] = filteredRigGroups.slice(insertPosition);

      const updatedRigsList: RigsGroup[] = [...left, current, ...right];

      this.storage.updateRigs(updatedRigsList);
    } else if (placement === 'after') {
      const insertPosition = filteredRigGroups.indexOf(placeOn);
      const left: RigsGroup[] = filteredRigGroups.slice(0, insertPosition + 1);
      const right: RigsGroup[] = filteredRigGroups.slice(insertPosition + 1);

      const updatedRigsList: RigsGroup[] = [...left, current, ...right];

      this.storage.updateRigs(updatedRigsList);
    }

    this.recalculatePositions();

    return this.storage.rigsGroups;
  }
}

export namespace RigsChartDataModel {
  export type ViewItem = LoadingRigOperations | IChartElement;

  export interface IRigsChartElementSizesGetters {
    /** Height of collapse header. */
    groupHeader(): number;
    /** Margin between collapses (groups of rows). */
    groupMargin(): number;
    /** Height of compact header. */
    rowHeaderCompact(count?: number): number;
    /** Height of wells group card. */
    cardGroupHeader(): number;
    /** Top and bottom margin for wells group card. */
    cardGroupMargin(): number;
    /** Wells group card padding. */
    cardGroupPadding(): number;
    /** Well card height, depending on well fields count. */
    card(attributesNumber: number): number;
    /** Margin between well card and wells group header. */
    cardMargin(): number;
    rowHeaderMinHeight(): number;
  }

  export type ChartSettings = {
    calculation: IRigsChartElementSizesGetters;
  };

  export interface IChartRig extends IChartElement {
    items: (PadRigOperation | LoadingRigOperations)[] | undefined;
    index: number | undefined;
    setIndex(index: number): void;
    setY(value: Range<number>): void;
    parentGroup?: IChartRigsGroup;
  }

  export interface IChartRigsGroup<TRig = IChartRig> extends IChartElement {
    isCollapsed: boolean;
    rowsStart: number | null;
    rowsEnd: number | null;
    items: TRig[];
    setIsCollapsed(isCollapsed: boolean): void;
    setRowsY(rowsY: Range<number | null>): void;
    setY(value: Range<number>): void;
  }

  export interface IChartPadRigOperation extends IChartElement {
    id: number;
    x: Range<number>;
    y: Range<number>;
    readonly parentRig: RigsChartDataModel.IChartRig;
  }

  export interface IChartWellRigOperation extends IChartElement {
    id: number;
    x: Range<number> | null;
    y: Range<number>;
    readonly parentRig: RigsChartDataModel.IChartRig;
    parentPad?: RigsChartDataModel.IChartPadRigOperation;
  }

  export type OrderChangeInfo = {
    insertion: number;
    targetRigId: number;
    initialRigId?: number;
    insertAfter?: number;
    insertOnPlace?: number;
    startDate?: number;
  };
}
