import { action, computed, makeObservable, observable } from 'mobx';

import { ChartGroupType } from './chart-group-type';

export class ChartGrouping {
  private readonly defaultValue: ChartGroupType;

  @observable private _value: ChartGroupType | null;

  constructor(defaultValue: ChartGroupType) {
    this.defaultValue = defaultValue;
    this._value = this.defaultValue;

    makeObservable(this);
  }

  @computed
  get value(): ChartGroupType {
    return this._value ?? this.defaultValue;
  }

  @action.bound
  setValue(value: ChartGroupType | null) {
    this._value = value;
  }
}
